import { makeStyles } from "@material-ui/styles";

const ContactLabelStyles = makeStyles((theme) => ({
  contactLabel: {
    padding: theme.spacing(10),
    background: "#f44336",
    textAlign: "center",
    justifyContent: "center",
  },
  labelTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
    },
    fontSize: "4rem",
    fontFamily: "Shadows Into Light, cursive",
    padding: "0 0 20px 0",
  },
  secondaryText: {
    color: "#fff",
  },
  contactLabelLink: {
    textDecoration: "none",
  },
  contactLabelText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
    color: "#fff",
    textDecoration: "none",
    fontSize: "1.5rem",
    transition: "0.2s ease",
    cursor: "pointer",
    borderBottom: "2px solid #000",
    fontFamily: "Shadows Into Light, cursive",
    borderRadius: "2.5px",
    "&:hover": {
      color: "#000",
    },
  },
}));

export default ContactLabelStyles;
