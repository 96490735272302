import { makeStyles } from "@material-ui/styles";
import heroImage from "../../Images/ContactPictures/contactHero.jpg";

const ContactPageStyles = makeStyles((theme) => ({
  contactPage: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    height: "auto",
    backgroundColor: "#000",
  },
  contactHeroContainer: {
    position: "relative",
    height: "750px",
    padding: "64px 0",
  },
  contactHeroImage: {
    backgroundImage: `url(${heroImage})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  contactHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px",
    },
    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  contactTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
    fontFamily: "Shadows Into Light, cursive",
    position: "relative",
  },
  reimaginedText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  contactPageContent: {
    padding: "128px 15%",
  },
  contactDetails: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "start",
    },
    justifyContent: "center",
  },
  contactDetailsHeader: {
    fontSize: "2.5rem",
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    borderBottom: "1px solid #ee1d23",
  },
  contactText: {
    fontSize: "0.9rem",
    fontFamily: "Poppins",
    color: "#fff",
  },
  mapContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "350px",
      margin: "10px 0",
    },
    height: "500px",
    width: "100%",
    position: "relative",
  },
}));

export default ContactPageStyles;
