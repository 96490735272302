import balletPicNew from "../../../Images/ClassPictures/balletClassPic.jpg";
import contemporaryImage from "../../../Images/ClassPictures/contemporaryClassPic.jpg";
import modernImage from "../../../Images/ClassPictures/modernClassPic.jpg";
import acroImage from "../../../Images/ClassPictures/acroClassPic.jpg";
import streetDancePic from "../../../Images/ClassPictures/streetDanceClassPic.jpg";
import tapDancePic from "../../../Images/ClassPictures/tapDanceClassPic.jpg";
import zumbaImage from "../../../Images/ClassPictures/zumbaClassPic.jpg";
import actingImage from "../../../Images/ClassPictures/actingClassPic.jpg";
import musicalTheatreImage from "../../../Images/ClassPictures/musicalTheatrePic.jpg";
import singingPic from "../../../Images/ClassPictures/singingPic.JPG";
import weddingDancePic from "../../../Images/ClassPictures/weddingDancePic.jpg";
import ballroomPic from "../../../Images/ClassPictures/ballroomPic.jpg";
import privateTuition from "../../../Images/ClassPictures/privateTuitionPic.jpg";

const classesGrid = [
  {
    id: 1,
    image: balletPicNew,
    danceType: "Ballet",
    danceSummary:
      "Ballet is the foundation of all dance styles, teaching perseverance, flexibility, stamina and strength. Many of our students attend professional associate programmes, such as the Northern Ballet, York Scholarship Centre and The Royal Ballet. We teach the RAD syllabus from babies to advanced level, and offer Elite Ballet classes to those with the appropriate aptitude, discipline and work ethic.",
  },
  {
    id: 2,
    image: modernImage,
    danceType: "Modern",
    danceSummary:
      "Modern dance is often seen on the stages of musical productions and is known for its theatrical qualities. From floor work to jumps, partnering and improvisation, Modern is an essential asset for young dancers wanting to develop into a well-rounded performer.",
  },
  {
    id: 3,
    image: contemporaryImage,
    danceType: "Contemporary",
    danceSummary:
      "Contemporary stems from the core or torso of the body, and uses elements that break the conventional style of dance. Using their natural energy to portray personal expression in their dance techniques, this style has fast become one of the most dominant genres for dancers throughout the world.",
  },
  {
    id: 4,
    image: musicalTheatreImage,
    danceType: "Musical Theatre",
    danceSummary:
      "Musical Theatre combines song, dance and dialogue to tell a story. Encouraging students to sharpen their acting and singing skills, this is a perfect choice for those wanting to build their stage confidence and to express themselves.",
  },
  {
    id: 5,
    image: streetDancePic,
    danceType: "Street",
    danceSummary:
      "Our Street class is a fun, energetic and upbeat style as seen in many commercial pop videos, TV and film. This is a great choice for all ages.",
  },
  {
    id: 6,
    image: acroImage,
    danceType: "Acro",
    danceSummary:
      "Acro is a new blend of dance styles, combining classical techniques with acrobatic skills. While all dance is physical, this form is particularly athletic and has unique choreography due to its use of acrobatics in a dance environment. Acro is fantastic at increasing flexibility, strength and balance; the more flexible you are, the better range of general movement you have!",
  },
  {
    id: 7,
    image: actingImage,
    danceType: "Acting",
    danceSummary:
      "Acting is a fun and dynamic class where our budding actors can get creative and develop key skills. Confidence building, developing the voice, and improvisation are crucial aspects of the class. Throughout the year, students will have the opportunity to take exams and also build on their screen acting skills!",
  },
  {
    id: 8,
    image: singingPic,
    danceType: "Singing",
    danceSummary:
      "Singing is the act of producing musical sounds with the voice and augments regular speech by the use of sustained tonality, rhythm, and a variety of vocal techniques. Remember, everyone has a voice... we just have to learn how to use it.",
  },
  {
    id: 9,
    image: zumbaImage,
    danceType: "Zumba",
    danceSummary:
      "Join the party! Zumba is a highly energetic style of dance which is too fun to feel like a workout, with instrumental music keeping you bouncing and coming back for more. We have trained licensed instructors to ensure we keep you moving!",
  },
  {
    id: 10,
    image: tapDancePic,
    danceType: "Tap",
    danceSummary:
      "Tap is a great way for students of all ages to develop rhythm, coordination, style and musicality. Our classes allow students to refine the art of dancing with precision and ease, working through the ISTD graded syllabus to gain Ofqual qualifications. With current and past students having successfully gained places on the Tap Attack associates programme, we encourage passionate students to learn from the 29-time world champion team alongside their training at De Burnays Dance Centre.",
  },
  {
    id: 11,
    image: weddingDancePic,
    danceType: "Wedding Dance",
    danceSummary:
      "At arguably the biggest day of your life, sometimes it's nice to be able to have the skills to make your first dance extra special (and to impress your guests!). Whether simple and elegant or a little more complex, we offer classes to ensure your dream wedding day ticks all of the boxes.",
  },
  {
    id: 12,
    image: ballroomPic,
    danceType: "Ballroom",
    danceSummary:
      "Taught by Anatoly Buryak, worldwide and European champion with over 30 years professional experience, we offer ballroom classes such as Cha Cha, Samba, Foxtrot, Waltz, Tango or Rumba from beginner to advanced level.",
  },
  {
    id: 13,
    image: privateTuition,
    danceType: "Private Tuition",
    danceSummary:
      "Whether you are eager to fine-tune your current skillset or looking to ease your way in to dance through one-on-ones, we offer private tuition in all classes to take your ability to the next level.",
  },
];

export default classesGrid;
