import React from "react";
import HomeStyles from "./HomeStyles.js";
import { Grid } from "@material-ui/core";

export default function Home() {
  const classes = HomeStyles();

  return (
    <div className={classes.homeContainer}>
      <div className={classes.backgroundImage} alt="Home Page Cover" />
      <Grid className={classes.homePageTextContainer}>
        <h1 className={classes.homePageTitle}>
          We are De<span className={classes.secondaryText}>Burnays</span>.
        </h1>
        <Grid container>
          <h2 className={classes.homePageSummary}>
            De Burnays Dance Centre is an established, locally owned dance and
            performing arts school which strives for excellence.
          </h2>
        </Grid>
      </Grid>
    </div>
  );
}
