import React, { useEffect } from "react";
import StaffStyles from "./StaffStyles.js";
import staffDetails from "./StaffGridDetails.js";
import { Typography, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

const Staff = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = StaffStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div className={classes.staffContainer}>
        <div className={classes.staffHeroContainer} alt="Home Page Cover">
          <div className={classes.staffBackgroundImage} />
          <Grid container className={classes.staffHeroText} lg={8} sm={12}>
            <h1 className={classes.staffHeroTitle}>
              Meet <span className={classes.secondaryText}>The Family</span>.
            </h1>
          </Grid>
        </div>

        <Grid className={classes.staffGridContainer}>
          {staffDetails.map((staffDetail) => {
            const {
              id,
              firstName,
              surname,
              summary1,
              summary2,
              image,
            } = staffDetail;
            return id % 2 === 0 && !matches ? (
              <>
                <Grid
                  item
                  md={12}
                  sm={12}
                  container
                  className={classes.staffDetailsContainer}
                >
                  <Grid
                    item
                    md={6}
                    sm={12}
                    container
                    alignContent="center"
                    className={classes.staffTextContainer}
                  >
                    <Grid item md={12}>
                      <Typography
                        className={classes.firstNameStyle}
                        variant="h1"
                      >
                        {firstName}
                        <span className={classes.secondaryText}>{surname}</span>
                      </Typography>
                      <Typography className={classes.summaryStyle}>
                        {summary1}
                      </Typography>
                      <Typography className={classes.summaryStyle}>
                        {summary2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    container
                    direction="column"
                    alignContent="center"
                    className={classes.staffPicContainer}
                  >
                    <img src={image} className={classes.staffPic} alt="Staff" />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  md={12}
                  sm={12}
                  container
                  className={classes.staffDetailsContainer}
                >
                  <Grid
                    item
                    md={6}
                    container
                    direction="column"
                    alignContent="center"
                    className={classes.staffPicContainer}
                  >
                    <img
                      src={image}
                      className={classes.staffPicAlt}
                      alt="Staff"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    container
                    alignContent="center"
                    className={classes.staffTextContainer}
                  >
                    <Grid item md={12}>
                      <Typography
                        className={classes.firstNameStyle}
                        variant="h1"
                      >
                        {firstName}
                        <span className={classes.secondaryText}>{surname}</span>
                      </Typography>
                      <Typography className={classes.summaryStyle}>
                        {summary1}
                      </Typography>
                      <Typography className={classes.summaryStyle}>
                        {summary2}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default Staff;
