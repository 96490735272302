import aboutImage from "../../Images/AboutPictures/aboutImage.jpeg";
import aboutImage2 from "../../Images/AboutPictures/aboutImage2.jpg";
import aboutImage3 from "../../Images/AboutPictures/aboutImage3.jpg";

const AboutGridDetails = [
  {
    id: 1,
    image: aboutImage,
    p1:
      "Established over 25 years ago, De Burnays Dance Centre is a fun, friendly and disciplined environment for children of all ages and ability. We are located in Retford’s town centre in the beautiful old Magistrates Court, which is filled with character and history.",
    p2:
      "De Burnays relocated to these premises in 2014 and brought this magnificent building back to life. Once an empty shell, it is now full of fun, laughter and inspiration.",
    p3:
      "We provide exceptional technique training in an environment that inspires and nurtures the students’ love of dance and performing arts.",
    p4:
      "Exterior view of the Old Court House, Exchange Street, Retford. Erected in 1867.",
  },
  {
    id: 2,
    image: aboutImage2,
    p1:
      "People are drawn to dance for very different reasons and at De Burnays, there is place for everyone.",
    p2:
      "Although students attend for either recreational or professional training, many of our students have gone on to have successful careers in the dance and the Performing Arts industry with professional companies around the world.",
    p3:
      "At De Burnays students learn how to learn, where they value the journey as much as the outcomes.",
  },
  {
    id: 3,
    image: aboutImage3,
    p1:
      "The mission at Team DB is to inspire our students, and instill a lasting love and appreciation for dance through artistic excellence, innovative choreography, and quality education. Our goals are to build self esteem, confidence and all-around physical well being.",
    p2:
      "Many students start their journey with us in preschool and continue until they leave for further education, enjoying the huge benefits their training has given to them over the years physically, personally, emotionally and socially.",
  },
];

export default AboutGridDetails;
