import React, { useEffect } from "react";
import AlumniPageStyles from "./AlumniPageStyles.js";
import alumniGridDetails from "./AlumniGridDetails.js";
import { Grid } from "@material-ui/core";

const AlumniPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = AlumniPageStyles();

  return (
    <div className={classes.alumniPage}>
      <div className={classes.alumniHeroContainer} alt="Home Page Cover">
        <div className={classes.alumniHeroImage} />
        <Grid container className={classes.alumniHeroText} lg={8} sm={12}>
          <h1 className={classes.alumniTitle}>
            Our <span className={classes.secondaryText}>Alumni</span>.
          </h1>
        </Grid>
      </div>
      <Grid className={classes.alumniContext}>
        <div className={classes.alumniGrid}>
          {alumniGridDetails.map((alumni) => {
            const { id, firstName, surname, summary, image } = alumni;
            return (
              <div className={classes.alumniDetailsContainer}>
                <div className={classes.alumniPicContainer}>
                  <img
                    key={id}
                    src={image}
                    className={classes.alumniPic}
                    alt="alumni"
                  />
                </div>

                <Grid container className={classes.alumniTextContainer}>
                  <Grid item md={12} className={classes.alumniText}>
                    <div className={classes.firstNameStyle}>
                      {firstName}
                      <span> </span>
                      <span className={classes.secondaryText}>{surname}</span>
                    </div>
                    <div className={classes.summaryStyle}>{summary}</div>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      </Grid>
    </div>
  );
};

export default AlumniPage;
