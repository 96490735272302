import React from "react";
import ContactContentStyles from "./FooterContentStyles.js";
import { Grid, Typography, IconButton } from "@material-ui/core/";
import FooterLinks from "./FooterLinks/FooterLinks";
import FooterDetails from "./FooterContactDetails/FooterContactDetails.js";
import FooterSocials from "./FooterSocialLinks/FooterSocialLinks.js";
import { Link } from "react-router-dom";
import logo from "../../../Images/Icons/de-burnays-logo-main.png";

const ContactContent = () => {
  const classes = ContactContentStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={4} className={classes.logoContainer}>
          <img src={logo} className={classes.logoStyle} alt="DeBurnay's Logo" />
          <Grid
            container
            xs={12}
            s={12}
            m={3}
            className={classes.iconContainer}
          >
            {FooterSocials.map((social) => {
              const { id, url, Icon } = social;
              return (
                <IconButton
                  aria-label="settings"
                  className={classes.iconButtonStyle}
                  id={id}
                >
                  <a href={url} add target="_blank" rel="noopener noreferrer">
                    <Icon className={classes.iconStyle} />
                  </a>
                </IconButton>
              );
            })}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={8}>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.contactGrid}
          >
            <Grid
              container
              xs={12}
              sm={4}
              md={4}
              className={classes.contactTextStyle}
            >
              <Typography>
                <h2 className={classes.contactTitle}>Quick Links</h2>
                {FooterLinks.map((link) => {
                  const { linkName, url } = link;
                  return (
                    <>
                      <Link to={url} className={classes.quickLink}>
                        {linkName}
                      </Link>
                      <br />
                    </>
                  );
                })}
              </Typography>
            </Grid>
            {FooterDetails.map((detail) => {
              const { title } = detail;
              return (
                <Grid
                  container
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.contactTextStyle}
                >
                  <Typography>
                    <h2 className={classes.contactTitle}>{title}</h2>
                    <span className={classes.contactTextStyle}>
                      {detail.info1}
                      <br /> {detail.info2}
                      <br /> {detail.info3}
                      <br />
                      {detail.info4}
                      <br />
                      {detail.info5}
                      <br />
                      {detail.info6}
                      <br />
                      {detail.info7}
                    </span>
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactContent;
