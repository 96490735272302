import { makeStyles } from "@material-ui/styles";
import juniorsHero from "../../../Images/JuniorPictures/juniorsHero.jpg";

const JuniorPageStyles = makeStyles((theme) => ({
  juniorsContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 0 0 ",
    },
    position: "relative",
    backgroundColor: "#000",
    color: "#fff",
    overflow: "hidden",
    height: "auto",
  },
  juniorsHeroContainer: {
    position: "relative",
    height: "750px",
  },
  juniorsBackgroundImage: {
    backgroundImage: `url(${juniorsHero})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  juniorsHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px",
    },
    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  juniorsHeroTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  juniorPageContainer: {
    height: "auto",
    background: "#000",
    color: "#fff",
    position: "relative",
    overflow: "hidden",
    padding: "128px 0 0 0",
  },
  juniorContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "64px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0px",
    },
    padding: "128px 32px",
  },
  juniorContentAlt: {
    [theme.breakpoints.down("sm")]: {
      padding: "64px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0px",
    },
    padding: "128px 32px",
    backgroundColor: "#131212",
  },
  juniorTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "3.2rem",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "30px",
  },
  juniorText: {
    [theme.breakpoints.down("sm")]: {
      padding: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    fontFamily: "Poppins",
    padding: "64px",
    height: "100%",
    alignSelf: "center",
  },
  juniorSummaryText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  juniorImageContainer: {
    height: "700px",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

export default JuniorPageStyles;
