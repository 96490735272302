import React, { useEffect } from "react";
import NotFoundStyles from "./NotFoundStyles.js";
import { Typography, Grid } from "@material-ui/core";

export default function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = NotFoundStyles();
  return (
    <React.Fragment>
      <div className={classes.notFoundPage}>
        <Grid className={classes.notFoundText}>
          <Typography className={classes.notFoundTitle}>
            Oops<span className={classes.reimaginedText}>!</span>
          </Typography>
          <Typography className={classes.notFoundSubTitle}>
            We couldn't find the page you are looking for
            <span className={classes.reimaginedText}>.</span>
          </Typography>
        </Grid>
      </div>
 
    </React.Fragment>
  );
}
