import { makeStyles } from "@material-ui/styles";

const FooterStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: "#131312",
    height: "auto",
    backgroundAttachment: "fixed",
    padding: "0 0 64px 0",
  },
  footerContent: {
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(3) },
    padding: "64px",
  },
  mdbLogoLink: {
    color: "#313130",
    textDecoration: "none",
    transition: "0.2s",
    fontSize: "0.8rem",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  footerSmallPrint: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 8px",
      textAlign: "center",
    },
    color: "#313129",
    padding: "8px 0 8px 64px",
    fontSize: "0.8rem",
  },
  smallPrintText: {
    padding: "4px 0",
    transition: "0.2s ease",
    "&:hover": {
      "& $zTenLink": {
        color: "#fff",
      },
      color: "#fff",
    },
  },
  zTenLink: {
    textDecoration: "none",
    color: "#313130",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23!important",
    },
  },
}));

export default FooterStyles;
