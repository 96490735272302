import { makeStyles } from "@material-ui/styles";

const ContactContentStyles = makeStyles((theme) => ({
  // logoContainer: {
  //   textAlign: "center",
  // },
  logoStyle: {
    width: "50%",
    maxWidth: "200px",
    padding: "32px 0",
  },
  contactGrid: {
    textAlign: "left",
    justifyContent: "center",
  },
  contactTextStyle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
    color: "#fff",
    fontSize: "0.9rem",
    textAlign: "left",
    fontFamily: "Poppins",
  },
  contactTitle: {
    fontSize: "1.5rem",
    borderBottom: "1px solid red",
    fontFamily: "Shadows Into Light, cursive",
  },
  quickLink: {
    textDecoration: "none",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    fontFamily: "Poppins",
    transition: "ease 0.1s",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  iconContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  iconGrid: {
    [theme.breakpoints.down("sm")]: { padding: "10% 0" },
    justifyContent: "space-evenly",
  },
  iconButtonStyle: {
    cursor: "default",
  },
  iconStyle: {
    color: "#fff",
    transform: "scale(1.4)",
    transition: "0.2s",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  divider: {
    [theme.breakpoints.down("xs")]: {
      margin: "1rem",
    },
    display: "block",
    height: "1px",
    border: "0",
    borderTop: "1px solid #f44336",
    margin: "1em 0",
    marginBottom: "25px",
    marginRight: "auto",
    width: "50%",
  },
  logoContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    position: "relative",
    height: "auto",
  },
}));

export default ContactContentStyles;
