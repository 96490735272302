import competitionIcon from "../../Images/Icons/competitive.svg";
import shoeIcon from "../../Images/Icons/ballet.svg";
import homeIcon from "../../Images/Icons/house.svg";

const overviewCardGridData = [
  {
    icon: homeIcon,
    title: "Local and Loyal",
    summary:
      "Operating for over 25 years and based in the Old Courthouse, our mission is to teach excellence to our local community.",
    link: "/about",
  },
  {
    icon: shoeIcon,
    title: "Quality Teaching",
    summary:
      "Being ISTD, RAD and LAMDA registered, we offer an extensive range of classes taught by an experienced, dedicated and enthusiastic team.",
    link: "/team",
  },
  {
    icon: competitionIcon,
    title: "Competitions and Exams",
    summary:
      "All students have the chance to compete at festivals and undergo exams, allowing them to perform and build their confidence.",
    link: "/competition",
  },
];

export default overviewCardGridData;
