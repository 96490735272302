import React, { useEffect } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./Components/Main/Main.js";
import Navbar from "./Components/Navbar/Navbar.js";
import Footer from "./Components/Footer/Footer.js";
import About from "./Pages/About/About.js";
import Staff from "./Pages/Staff/StaffPage/Staff.js";
import Shop from "./Pages/Shop/ShopPage/ShopPage.js";
import Alumni from "./Pages/Alumni/AlumniPage/AlumniPage.js";
import Classes from "./Pages/ClassesMain/ClassesPage/ClassesPages.js";
import Juniors from "./Pages/Juniors/JuniorPage/JuniorPage.js";
import CompetitionExamPage from "./Pages/CompetitionExamPages/CompetitionExamPage.js";
import News from "./Pages/News/NewsPage/NewsPage.js";
import Gallery from "./Pages/Gallery/GalleryPage/GalleryPage.js";
import Contact from "./Pages/Contact/ContactPage.js";
import NotFound from "./Pages/NotFound/NotFound.js";
import ScrollTopArrow from "./Components/ScrollTopArrow/ScrollTopArrow.js";
import ContactLabel from "./Components/ContactLabel/ContactLabel.js";

export default function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Router>
      <div className="app">
        <Navbar />
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Baskervville&family=Poppins:wght@300&family=Roboto:wght@100&display=swap&&family=Mukta:wght@300;400&family=Shadows+Into+Light&display=swapfamily=Mukta:wght@300;400&family=Open+Sans:wght@600&display=swap');
        </style>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/team" component={Staff} />
          <Route exact path="/classes" component={Classes} />
          <Route exact path="/juniors" component={Juniors} />
          <Route exact path="/about" component={About} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/alumni" component={Alumni} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/news" component={News} />
          <Route exact path="/competition" component={CompetitionExamPage} />
          <Route exact path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
        <ContactLabel />
        <Footer />
        <ScrollTopArrow />
      </div>
    </Router>
  );
}
