import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import ChildProtectionDoc from "../../Files/ChildProtectionGuidePolicy.pdf";
import OverviewStyles from "./OverviewStyles.js";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import overviewCardGridData from "./OverviewCardData.js";

export default function Overview() {
  const classes = OverviewStyles();
  return (
    <div className={classes.overviewContainer}>
      <Grid container direction="column">
        <Grid item container className={classes.gridContent}>
          <Grid item container className={classes.titleGrid}>
            <Grid
              item
              md={12}
              data-aos="fade-up"
              data-aos-delay="200"
              className={classes.overviewTextContainer}
            >
              <h2 className={classes.overviewTitleStyle}>
                Put your
                <span className={classes.secondaryText}> dance shoes on</span>.
              </h2>
              <div className={classes.summaryStyle}>
                De Burnays Dance Centre is a family owned business and has been
                operating for over 25 years. With over 100 years of collective
                dancing and teaching experience, we provide those wishing to
                either begin their dance career, to get fit or to simply have
                fun with the best foundations and experience to make these
                aspirations possible.
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <div className={classes.overviewCardContainer}>
            {overviewCardGridData.map((card) => {
              const { icon, title, summary, link } = card;
              return (
                <div className={classes.overviewCard} md={4}>
                  <img
                    src={icon}
                    className={classes.overviewCardIcon}
                    alt="Card"
                  />
                  <Grid item container className={classes.overviewCardText}>
                    <div className={classes.overviewCardTitle}>{title}</div>
                  </Grid>
                  <hr className={classes.divider} />
                  <Grid container>
                    <Grid className={classes.overviewCardSummary}>
                      {summary}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.overviewButtonContainer}>
                    <Link to={link} className={classes.overviewButtonLink}>
                      <Button className={classes.overviewButton}>
                        Read More
                        <ArrowForwardIcon className={classes.arrow} />
                      </Button>
                    </Link>
                  </Grid>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <div className={classes.childProtectionContainer}>
        <a
          href={ChildProtectionDoc}
          download
          className={classes.childProtectionText}
        >
          Our Child Protection Guide Policy
          <ArrowDownwardIcon className={classes.downArrow} />
        </a>
      </div>
    </div>
  );
}
