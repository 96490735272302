import { makeStyles } from "@material-ui/core/styles";

const ScrollTopArrowStyles = makeStyles((theme) => ({
  backToTopButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default ScrollTopArrowStyles;
