import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import JuniorsMainPageStyles from "./JuniorMainStyles.js";
import ShopPage from "../Shop/ShopMain.js";
import AlumniPage from "../Alumni/AlumniMain.js";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const JuniorsMainPage = () => {
  const classes = JuniorsMainPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div className={classes.juniorsMainContainer}>
        {!matches ? (
          <Grid container className={classes.juniorsMainInfoContainer}>
            <Grid container item sm={12} md={6} lg={6}>
              <div className={classes.juniorsMainPic} />
            </Grid>
            <Grid
              item
              container
              className={classes.juniorsMainText}
              sm={12}
              md={6}
              lg={6}
            >
              <Grid container xs={12}>
                <h2 data-aos="fade-left" className={classes.juniorsMainTitle}>
                  Little<span className={classes.secondaryText}> moves</span>.
                </h2>
              </Grid>
              <Grid container xs={12}>
                <br />
                <div className={classes.summaryStyle}>
                  Our team are qualified to care for, look after and teach
                  children. Specialising in building the foundations of dance in
                  your little ones, we give them a focus, a sense of achievement
                  and above all, a fun environment to learn.
                  <Grid
                    container
                    className={classes.juniorsMainButtonContainer}
                  >
                    <Link
                      to="/juniors"
                      className={classes.juniorsMainButtonLink}
                    >
                      <Button className={classes.juniorsMainButton}>
                        Juniors <ArrowForwardIcon className={classes.arrow} />
                      </Button>
                    </Link>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.juniorsMainInfoContainer}>
            <Grid
              item
              container
              className={classes.juniorsMainText}
              sm={12}
              md={6}
              lg={6}
            >
              <Grid container xs={12}>
                <h2 data-aos="fade-right" className={classes.juniorsMainTitle}>
                  Little<span className={classes.secondaryText}> moves</span>.
                </h2>
              </Grid>
              <Grid container xs={12}>
                <br />
                <div className={classes.summaryStyle}>
                  With most of our team qualified to care for, look after and
                  teach children, we specialise in building the foundations of
                  dance in your little ones, giving them a focus, a sense of
                  achievement and above all, a fun environment to learn.
                  <Grid
                    container
                    className={classes.juniorsMainButtonContainer}
                  >
                    <Link
                      to="/juniors"
                      className={classes.juniorsMainButtonLink}
                    >
                      <Button className={classes.juniorsMainButton}>
                        Juniors <ArrowForwardIcon className={classes.arrow} />
                      </Button>
                    </Link>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6} lg={6}>
              <div className={classes.juniorsMainPic} />
            </Grid>
          </Grid>
        )}
      </div>
      <ShopPage />
      <AlumniPage />
    </>
  );
};

export default JuniorsMainPage;
