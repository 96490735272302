import { makeStyles } from "@material-ui/styles";

const MapAPIStyles = makeStyles((theme) => ({
  map: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
  },
  mapContainer: {
    textAlign: "-webkit-center",
  },
  mapStyle: {
    height: "400px",
    width: "400px",
  },
}));

export default MapAPIStyles;
