import React from "react";
import ContactLabelStyles from "./ContactLabelStyles.js";
import { Grid } from "@material-ui/core";

const ContactLabel = () => {
  const classes = ContactLabelStyles();
  return (
    <Grid container className={classes.contactLabel}>
      <Grid className={classes.labelText} md={12}>
        <div className={classes.labelTitle}>
          Fancy a <span className={classes.secondaryText}>dance</span>?
        </div>
        <div className={classes.contactLabelLink}>
          <a
            href="mailto:enquiries@deburnays.co.uk"
            className={classes.contactLabelText}
          >
            Get In Touch
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactLabel;
