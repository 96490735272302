import React, { useEffect } from "react";
import AboutStyles from "./AboutStyles.js";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import AboutGridDetails from "./AboutGridDetail.js";

const About = () => {
  const classes = AboutStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.aboutContainer}>
        <div className={classes.aboutHeroContainer} alt="Home Page Cover">
          <div className={classes.aboutHeroImage} />
          <Grid container className={classes.aboutHeroText} lg={8} sm={12}>
            <h1 className={classes.aboutTitle}>
              What <span className={classes.secondaryText}>We Do</span>.
            </h1>
          </Grid>
        </div>
        <Grid container className={classes.aboutContent}>
          {AboutGridDetails.map((gridItem) => {
            const { id, image, p1, p2, p3, p4 } = gridItem;
            return id % 2 !== 0 && !matches ? (
              <Grid container className={classes.aboutItem} xs={12}>
                <Grid
                  id={id}
                  container
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.aboutImageContainer}
                >
                  <Grid>
                    <img src={image} className={classes.aboutImage} alt="" />
                  </Grid>
                  <Grid>
                    <p className={classes.courtHouseText}>{p4}</p>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  className={classes.aboutText}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <p>{p1}</p>
                  <p>{p2}</p>
                  <p>{p3}</p>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.aboutItemAlt} xs={12}>
                <Grid
                  item
                  container
                  className={classes.aboutText}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <p>{p1}</p>
                  <p>{p2}</p>
                  <p>{p3}</p>
                </Grid>
                <Grid
                  id={id}
                  container
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.aboutImageContainer}
                >
                  <img src={image} className={classes.aboutImage} alt="" />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default About;
