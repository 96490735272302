import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../Images/GalleryPictures/galleryMainPageImage2.jpg";

const GalleryMainPageStyles = makeStyles((theme) => ({
  galleryPageContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    height: "auto",
  },
  galleryPageBackgroundImage: {
    backgroundImage: `url(${backgroundImage})`,
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    filter: "brightness(25%)",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  galleryTitleContainer: { justifyContent: "center", position: "relative" },
  galleryTitle: {
    [theme.breakpoints.down("md")]: {
      padding: "32px 64px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
      padding: theme.spacing(2),
    },
    fontSize: "3.2rem",
    color: "#fff",
    fontFamily: "Shadows Into Light, cursive",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  galleryContainer: {
    justifyContent: "center",
    margin: "100px 0 250px 0",
  },
  gallery: {
    position: "relative",
  },
  galleryGridPic: {
    width: "100%",
    height: "100%",
  },
  galleryButtonContainer: {
    justifyContent: "center",
  },
  galleryButton: {
    color: "#fff",
    border: "2px solid #ee1d23",
    width: "auto",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  galleryButtonLink: {
    textDecoration: "none",
  },
}));

export default GalleryMainPageStyles;
