import shopImage1 from "../../../Images/ShopPictures/shopImg1.jpg";
import shopImage2 from "../../../Images/ShopPictures/shopImg2.jpg";

const ShopPageDetails = [
  {
    id: 1,
    image: shopImage1,
    p1:
      "Our dance shop is located on the ground floor of the Court House. We stock the R.A.D and I.S.T.D uniform and can supply many more regulation uniforms which are available to order.",
    p2:
      "We cater for all kinds of dance including ballet, modern, jazz, tap, theatre and character and stock extra of the biggest names in dancewear including Bloch, Capezio, Freed of London, Bunheads, 1st Position, Sansha, SoDanca, Mondor and Wear-Moi.",
  },

  {
    id: 2,
    image: shopImage2,
    p1:
      "We have a range ballet socks and tights, leotards, dance shoes, cross overs and warm ups, dance bags, gifts, hair accessories and pointe shoes.",
    p2:
      "If we don’t have it in stock, we will do our very best to get it for you.",
  },
];

export default ShopPageDetails;
