import React from "react";
import { Link } from "react-router-dom";
import AlumniMainPageStyles from "./AlumniMainPageStyles.js";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const AlumniMain = () => {
  const classes = AlumniMainPageStyles();
  return (
    <div className={classes.alumniMainPage}>
      <Grid container className={classes.alumniInfoContainer}>
        <Grid container item sm={12} md={6} lg={6}>
          <div className={classes.alumniPic} />
        </Grid>
        <Grid
          item
          container
          className={classes.alumniText}
          sm={12}
          md={6}
          lg={6}
        >
          <Grid container xs={12}>
            <h1 data-aos="fade-left" className={classes.alumniTitle}>
              Our
              <span className={classes.secondaryText}> Alumni</span>.
            </h1>
          </Grid>
          <Grid container xs={12}>
            <div className={classes.summaryStyle}>
              Many of our students at De Burnays Dance Centre go on to attend
              prestigious and esteemed dance colleges, West End theatre and
              famous dance productions.
              <Grid container className={classes.alumniButtonContainer}>
                <Link to="/alumni" className={classes.alumniButtonLink}>
                  <Button className={classes.alumniButton}>
                    Alumni
                    <ArrowForwardIcon className={classes.arrow} />
                  </Button>
                </Link>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlumniMain;
