import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../../Images/ShopPictures/shopHero.jpg";

const ShopPageStyles = makeStyles((theme) => ({
  shopPage: {
    height: "auto",
    background: "#000",
    position: "relative",
  },
  shopHeroContainer: {
    position: "relative",
    height: "750px",
  },
  shopBackgroundImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  shopHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "64px 32px",
    },
    position: "relative",
    padding: theme.spacing(16),
  },
  shopHeroTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  shopTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  shopContent: {
    backgroundColor: "#000",
  },
  shopText: {
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0",
    },
    fontFamily: "Poppins",
    padding: theme.spacing(4),
    alignSelf: "center",
  },
  shopSummary: {
    fontSize: "1rem",
    color: "#fff",
  },
  shopItem: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    width: "100%",
    padding: "64px 128px",
    backgroundColor: "#212020",
  },
  shopItemAlt: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
    width: "100%",
    padding: "64px 128px",
  },
  shopImageContainer: {
    [theme.breakpoints.up("md")]: {
      margin: "128px 0",
    },
    justifyContent: "center",
    overflow: "hidden",
  },
  shopImage: {
    width: "100%",
    height: "100%",
    minWidth: "600px",
  },
}));

export default ShopPageStyles;
