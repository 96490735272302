const FooterDetails = [
  {
    id: 1,
    title: "Address",
    info1: "The Courthouse",
    info2: "Exchange Street",
    info3: "Retford",
    info4: "Nottinghamshire",
    info5: "DN22 6BL",
  },
  {
    id: 2,
    title: "Opening Times",
    info1: "Monday: 15:30 - 21:00",
    info2: "Tuesday: 15:30 - 21:00",
    info3: "Wednesday: 15:30 - 21:00",
    info4: "Thursday: 15:30 - 21:00",
    info5: "Friday: Closed",
    info6: "Saturday: 09:00 - 16:00",
    info7: "Sunday: Closed",
  },
];

export default FooterDetails;
