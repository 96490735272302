import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import GalleryMainPageStyles from "./GalleryMainPageStyles.js";
import GalleryGridDetails from "./GalleryGridDetails.js";

export default function Gallery() {
  const classes = GalleryMainPageStyles();
  return (
    <div className={classes.galleryPageContainer}>
      <div className={classes.galleryPageBackgroundImage} />
      <Grid item container className={classes.galleryTitleContainer}>
        <h1 className={classes.galleryTitle}>
          Our <span className={classes.secondaryText}>Gallery</span>.
        </h1>
        <Grid container className={classes.galleryButtonContainer}>
          <Link to="/gallery" className={classes.galleryButtonLink}>
            <Button className={classes.galleryButton}>View Gallery</Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.galleryGridContent}>
        <Grid container lg={12} className={classes.galleryContainer}>
          {GalleryGridDetails.map((galleryImage) => {
            const { image } = galleryImage;
            return (
              <Grid container className={classes.gallery} xs={12} sm={6} md={4}>
                <img
                  src={image}
                  className={classes.galleryGridPic}
                  alt="Gallery"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
