import React from "react";
import { Grid } from "@material-ui/core";
import ContactComponentStyles from "./FooterStyles.js";
import FooterContent from "./FooterContent/FooterContent.js";

export default function Contact() {
  const classes = ContactComponentStyles();
  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerContent} container direction="column">
        <FooterContent />
      </div>
      <Grid className={classes.footerSmallPrint}>
        <Grid item className={classes.smallPrintText}>
          For further information on our photography, visit<span> </span>
          <a
            href="http://zten.co.uk/"
            alt="Z-Ten Media"
            add
            target="_blank"
            rel="noopener noreferrer"
            className={classes.zTenLink}
          >
            Z-Ten Media
          </a>
          .
        </Grid>
      </Grid>
    </div>
  );
}
