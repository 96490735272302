import React from "react";
import ClassesStylesMain from "./ClassesStylesMain.js";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function ClassesMain() {
  const classes = ClassesStylesMain();

  return (
    <div className={classes.classesContainer}>
      <Grid container className={classes.classesInfoContainer}>
        <Grid
          item
          container
          className={classes.classesText}
          sm={12}
          md={6}
          lg={6}
        >
          <Grid container xs={12}>
            <h1 data-aos="fade-right" className={classes.classesTitle}>
              Dance,
              <span className={classes.secondaryText}> for everyone</span>.
            </h1>
          </Grid>
          <Grid container xs={12}>
            <div className={classes.summaryStyle}>
              Whether you are dancing for fun, fitness or for competition, we
              offer a variety of dance lessons for all ages and abilities. With
              classes including Ballet, Musical Theatre, Contemporary and more,
              our approach is supportive and positive so you or your child’s
              love of dance can flourish. To help you decide if our studio is
              right for you, we offer a free trial class.
              <Grid container className={classes.classButtonContainer}>
                <Link to="/classes" className={classes.classButtonLink}>
                  <Button className={classes.classButton}>
                    Classes
                    <ArrowForwardIcon className={classes.arrow} />
                  </Button>
                </Link>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} lg={6}>
          <div className={classes.classesPic} />
        </Grid>
      </Grid>
    </div>
  );
}
