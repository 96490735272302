import { makeStyles } from "@material-ui/styles";
import juniorMainImage from "../../Images/JuniorPictures/juniorsMainImage.jpg";

const JuniorMainStyles = makeStyles((theme) => ({
  juniorsMainContainer: {
    padding: "128px 0 25% 0",
    position: "relative",
    backgroundColor: "#000",
    color: "white",
    overflow: "hidden",
    height: "auto",
    "&::before": {
      position: "absolute",
      content: "''",
      width: "60%",
      height: "175px",
      bottom: "-105px",
      left: 0,
      background: "#212020",
      transform: "skewY(10deg)",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      width: "60%",
      height: "175px",
      bottom: "-105px",
      right: 0,
      background: "#212020",
      transform: "skewY(-10deg)",
    },
  },
  juniorsMainText: {
    [theme.breakpoints.down("sm")]: {
      padding: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
    padding: "64px",
  },
  juniorsMainTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "2.5rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    maxWidth: "500px",
    margin: "auto",
    padding: "15px 0",
  },
  juniorsMainPic: {
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    width: "100%",
    height: "100%",
    justifyContent: "center",
    backgroundImage: `url(${juniorMainImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignSelf: "center",
  },
  juniorsMainButtonContainer: {
    padding: "20px 0",
  },
  juniorsMainButtonLink: {
    textDecoration: "none",
  },
  juniorsMainButton: {
    border: "2px solid #ee1d23",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
      "& $arrow": {
        transform: "translate(50%)",
        color: "#fff",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
  },
}));

export default JuniorMainStyles;
