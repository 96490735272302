import React from "react";
import { Link } from "react-router-dom";
import ShopStyles from "./ShopMainStyles.js";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const ShopMain = () => {
  const classes = ShopStyles();
  return (
    <div className={classes.shopPage}>
      <Grid className={classes.shopPageContainer}>
        <h1 className={classes.shopTitle}>
          What's in <span className={classes.secondaryText}> store</span>?
          <hr className={classes.divider} />
          <hr className={classes.divider2} />
        </h1>
        <Grid container className={classes.shopInfo}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.shopTextContainer}
          >
            <div className={classes.shopText}>
              We ensure that we provide all uniform and additional dance wear in
              our Dance Shop. From our official uniform to pointe shoes,
              leotards, socks, bags and accessories, come and see our shop to
              see our full selection.
              <Grid className={classes.shopButtonContainer}>
                <Link to="/shop" className={classes.shopButtonLink}>
                  <Button className={classes.shopButton}>
                    Shop
                    <ArrowForwardIcon className={classes.arrow} />
                  </Button>
                </Link>
              </Grid>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={6} className={classes.shopImageContainer}>
            <div className={classes.shopImage} alt="Shop" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShopMain;
