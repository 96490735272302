import React, { useState, useEffect } from 'react';
import { Grid, Modal } from '@material-ui/core';
import NewsPageStyles from './NewsPageStyles.js';
import NewsPageArticles from './NewsPageArticles.js';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function NewsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = NewsPageStyles();

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.newsPage}>
      <div className={classes.newsHeroContainer} alt="Home Page Cover">
        <div className={classes.newsBackgroundImage} />
        <Grid container className={classes.newsHeroText} lg={8} sm={12}>
          <h1 className={classes.newsHeroTitle}>
            Latest <span className={classes.secondaryText}>News</span>.
          </h1>
        </Grid>
      </div>
      <div className={classes.newsPageContainer}>
        <div className={classes.newsArticlesContainer}>
          {NewsPageArticles.map((article) => {
            if (!article.image) {
              const {
                id,
                title,
                date,
                summary,
                summary2,
                summary3,
                summary4,
                summary5,
              } = article;
              return (
                <div className={classes.article} key={id}>
                  <h2 className={classes.articleTitle}>{title}</h2>
                  <div className={classes.articleDate}>{date}</div>
                  <hr className={classes.divider} />
                  <p className={classes.articleSummary}>{summary}</p>
                  <p className={classes.articleSummary}>{summary2}</p>
                  <p className={classes.articleSummary}>{summary3}</p>
                  <p className={classes.articleSummary}>{summary4}</p>
                  <p className={classes.articleSummary}>{summary5}</p>
                </div>
              );
            } else {
              const {
                id,
                title,
                date,
                summary,
                summary2,
                summary3,
                summary4,
                image,
              } = article;
              return (
                <div className={classes.article} key={id}>
                  <Grid container>
                    <Grid xs={12} sm={12} md={6} lg={6}>
                      <h2 className={classes.articleTitle}>{title}</h2>
                      <div className={classes.articleDate}>{date}</div>
                      <hr className={classes.divider} />
                      <p className={classes.articleSummary}>{summary}</p>
                      <p className={classes.articleSummary}>{summary2}</p>
                      <p className={classes.articleSummary}>{summary3}</p>
                      <p className={classes.articleSummary}>{summary4}</p>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className={classes.articleImageContainer}
                    >
                      <img
                        src={image}
                        alt="News Poster"
                        className={classes.articleImage}
                        onClick={handleOpen}
                      />
                      {/* <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div style={modalStyle} className={classes.paper}>
                          <img
                            src={image}
                            className={classes.articleImageModal}
                            alt="News Article Poster"
                          />
                        </div>
                      </Modal> */}
                    </Grid>
                  </Grid>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
