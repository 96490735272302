import { makeStyles } from "@material-ui/styles";
import classesPic from "../../Images/ClassPictures/classMainImage2.jpg";

const ClassesStylesMain = makeStyles((theme) => ({
  classesContainer: {
    padding: "128px 0",
    position: "relative",
    backgroundColor: "#212020",
    color: "white",
    overflow: "hidden",
    height: "auto",
  },
  classesText: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
    padding: "64px",
  },
  classesTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2rem",
      textAlign: "justify",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "2.2rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
    padding: "15px 0",
  },
  classesPic: {
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    width: "100%",
    height: "100%",
    justifyContent: "center",
    backgroundImage: `url(${classesPic})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignSelf: "center",
  },
  classButtonContainer: {
    padding: "20px 0",
  },
  classButtonLink: {
    textDecoration: "none",
  },
  classButton: {
    border: "2px solid #ee1d23",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
      "& $arrow": {
        transform: "translate(50%)",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
    color: "#ee1d23",
  },
}));

export default ClassesStylesMain;
