const menuItems = [
  {
    id: 1,
    title: "Home",
    pageURL: "/",
  },
  {
    id: 2,
    title: "School",
    subHeadings: [
      {
        id: 1,
        title: "About",
        pageURL: "/about",
      },
      {
        id: 2,
        title: "Competition and Exams",
        pageURL: "/competition",
      },
      {
        id: 3,
        title: "The Team",
        pageURL: "/team",
      },
      {
        id: 4,
        title: "Alumni",
        pageURL: "/alumni",
      },
    ],
  },
  {
    id: 3,
    title: "Classes",
    subHeadings: [
      {
        id: 1,
        title: "Classes",
        pageURL: "/classes",
      },
      {
        id: 2,
        title: "Junior Classes",
        pageURL: "/juniors",
      },
    ],
  },
  {
    id: 4,
    title: "Shop",
    pageURL: "/shop",
  },
  {
    id: 5,
    title: "Gallery",
    pageURL: "/gallery",
  },
  {
    id: 6,
    title: "News",
    pageURL: "/news",
  },
  {
    id: 7,
    title: "Contact",
    pageURL: "/contact",
  },
];

export default menuItems;
