const ContactPageGridDetails = [
  {
    id: 1,
    title: "General Enquiries",
    info1: "Office: 01777 710006",
    info2: "Mobile: 07818 519299",
    info3: "enquiries@deburnays.co.uk",
  },
  {
    id: 2,
    title: "Office Hours",
    info1: "Monday: 15:30 - 21:00",
    info2: "Tuesday: 15:30 - 21:00",
    info3: "Wednesday: 15:30 - 21:00",
    info4: "Thursday: 15:30 - 21:00",
    info5: "Friday: Closed",
    info6: "Saturday: 09:00 - 16:00",
    info7: "Sunday: Closed",
  },
  {
    id: 3,
    title: "Term Times",
    info1: "Term 1: April, May, June",
    info2: "Term 2: July, August, September",
    info3: "Term 3: October, November, December",
    info4: "Term 4: January, February, March",
  },
];

export default ContactPageGridDetails;
