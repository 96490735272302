import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { galleryImages } from "./GalleryImages.js";

const GalleryPageComponent = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const openLightbox = useCallback((e, { photo, index }) => {
    setCurrentImage(index);
    setIsLightboxOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setIsLightboxOpen(false);
  };

  return (
    <React.Fragment>
      <Gallery photos={galleryImages} onClick={openLightbox} />
      <ModalGateway>
        {isLightboxOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={galleryImages.map((image) => ({
                ...image,
                srcset: image.srcSet,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  );
};

render(<GalleryPageComponent />, document.getElementById("root"));

export default GalleryPageComponent;
