import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import StaffMainPageStyles from "./StaffMainPageStyles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StaffMainPage = () => {
  const classes = StaffMainPageStyles();
  return (
    <>
      <div className={classes.staffMainContainer}>
        <Grid container className={classes.staffMainInfoContainer}>
          <Grid
            item
            container
            className={classes.staffMainText}
            sm={12}
            md={6}
            lg={6}
          >
            <Grid container xs={12}>
              <h2 data-aos="fade-right" className={classes.staffMainTitle}>
                We're pretty
                <span className={classes.secondaryText}> experienced</span>...
              </h2>
            </Grid>
            <Grid container xs={12}>
              <br />
              <div className={classes.summaryStyle}>
                From performing with the Royal Ballet, acting in theatre and on
                television to dancing around the world, our team consists of
                experienced professionals wanting to pass their knowledge and
                enthusiasm on to the next generation.
                <Grid container className={classes.staffMainButtonContainer}>
                  <Link to="/team" className={classes.staffMainButtonLink}>
                    <Button className={classes.staffMainButton}>
                      Team <ArrowForwardIcon className={classes.arrow} />
                    </Button>
                  </Link>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={6} lg={6}>
            <div className={classes.staffMainPic} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StaffMainPage;
