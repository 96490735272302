import LamdaLogo from "../../Images/affiliatesLogos/LamdaLogo.png";
import IstdLogo from "../../Images/affiliatesLogos/IstdRegisteredMemberLogo.png";
import RoyalBalletLogo from "../../Images/affiliatesLogos/royalAcademyLogo.jpg";
import ZumbaLogo from "../../Images/affiliatesLogos/ZumbaRegisteredLogo.png";

const affiliatesGrid = [
  { id: 1, image: IstdLogo, url: "https://www.istd.org/" },
  {
    id: 2,
    image: RoyalBalletLogo,
    url: "https://www.roh.org.uk/about/the-royal-ballet",
  },
  {
    id: 3,
    image: ZumbaLogo,
    url: "https://www.zumba.com/en-US",
  },
  {
    id: 4,
    image: LamdaLogo,
    url: "https://www.lamda.ac.uk/",
  },
];

export default affiliatesGrid;
