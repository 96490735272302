import { makeStyles } from "@material-ui/styles";

const NotFoundStyles = makeStyles((theme) => ({
  notFoundPage: {
    height: "100vh",
    backgroundColor: "#000",
  },
  notFoundText: {
    justifyContent: "center",
    textAlign: "center",
    padding: "220px 60px",
  },
  notFoundTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem",
    },
    fontSize: "5rem",
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
  },
  notFoundSubTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
    fontSize: "2.5rem",
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
  },
  reimaginedText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
}));

export default NotFoundStyles;
