import React, { useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import JuniorGridDetails from "./JuniorGridDetails.js";
import JuniorPageStyles from "./JuniorPageStyles";
import { useTheme } from "@material-ui/styles";

const JuniorPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = JuniorPageStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.juniorsContainer}>
        <div className={classes.juniorsHeroContainer} alt="Juniors Hero">
          <div className={classes.juniorsBackgroundImage} />
          <Grid container className={classes.juniorsHeroText} lg={8} sm={12}>
            <h1 className={classes.juniorsHeroTitle}>
              Junior <span className={classes.secondaryText}>Classes</span>.
            </h1>
          </Grid>
        </div>
      </div>

      <div className={classes.juniorPageContainer}>
        {JuniorGridDetails.map((details) => {
          const {
            id,
            image,
            title: { main, secondary },
            summary: { p1, p2, p3, p4, p5 },
          } = details;
          return id % 2 !== 0 && !matches ? (
            <Grid container className={classes.juniorContent}>
              <Grid
                item
                container
                className={classes.juniorImageContainer}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <img src={image} className={classes.juniorImage} alt="" />
              </Grid>
              <Grid
                item
                container
                className={classes.juniorText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <div data-aos="fade-left" className={classes.juniorTitle}>
                  {main}
                  <span className={classes.secondaryText}>{secondary}</span>.
                </div>
                <div>
                  <p className={classes.juniorSummaryText}>{p1}</p>
                  <p className={classes.juniorSummaryText}>{p2}</p>
                  <p className={classes.juniorSummaryText}>{p3}</p>
                  <p className={classes.juniorSummaryText}>{p4}</p>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.juniorContentAlt}>
              <Grid
                item
                container
                className={classes.juniorText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <div data-aos="fade-right" className={classes.juniorTitle}>
                  {main}
                  <span className={classes.secondaryText}>{secondary}</span>.
                </div>
                <div>
                  <p className={classes.juniorSummaryText}>{p1}</p>
                  <p className={classes.juniorSummaryText}>{p2}</p>
                  <p className={classes.juniorSummaryText}>{p3}</p>
                  <p className={classes.juniorSummaryText}>{p4}</p>
                  <p className={classes.juniorSummaryText}>{p5}</p>
                </div>
              </Grid>
              <Grid
                item
                container
                className={classes.juniorImageContainer}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <img src={image} className={classes.juniorImage} alt="" />
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
};

export default JuniorPage;
