import galleryImg from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg1.jpg";
import galleryImg2 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg2.jpg";
import galleryImg3 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg3.jpg";
import galleryImg4 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg4.jpg";
import galleryImg5 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg5.jpg";
import galleryImg6 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg6.jpg";
import galleryImg7 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg7.jpg";
import galleryImg8 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg8test.jpg";
import galleryImg9 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg9.jpg";
import galleryImg10 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg10.jpg";
import galleryImg11 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg11.jpg";
import galleryImg12 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg12.jpg";
import galleryImg13 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg13.jpg";
import galleryImg14 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg14.jpg";
import galleryImg15 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg15.jpg";
import galleryImg16 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg16.jpg";
import galleryImg17 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg17.jpg";
import galleryImg18 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg18.jpg";
import galleryImg19 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg19.jpg";
import galleryImg20 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg20.jpg";
import galleryImg21 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg21.jpg";
import galleryImg22 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg22.jpg";
import galleryImg23 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg23.jpg";
import galleryImg24 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg24.jpg";
import galleryImg25 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg25.jpg";
import galleryImg26 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg26.jpg";
import galleryImg27 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg27.jpg";
import galleryImg28 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg28.jpg";
import galleryImg29 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg29.jpg";
import galleryImg30 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg30.jpg";
import galleryImg31 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg31.jpg";
import galleryImg32 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg32.jpg";
import galleryImg33 from "../../Images/GalleryPictures/GalleryPagePictures/galleryImg33.jpg";

export const galleryImages = [
  {
    index: 0,
    src: galleryImg,
    width: 4,
    height: 3,
  },
  {
    index: 1,
    src: galleryImg2,
    width: 4,
    height: 3,
  },
  {
    index: 2,
    src: galleryImg3,
    width: 5,
    height: 4,
  },
  {
    index: 3,
    src: galleryImg4,
    width: 4,
    height: 3,
  },
  {
    index: 4,
    src: galleryImg5,
    width: 2,
    height: 3,
  },
  {
    index: 5,
    src: galleryImg6,
    width: 4,
    height: 3,
  },
  {
    index: 6,
    src: galleryImg7,
    width: 4,
    height: 3,
  },
  {
    index: 7,
    src: galleryImg8,
    width: 2,
    height: 3,
  },
  {
    index: 8,
    src: galleryImg9,
    width: 4,
    height: 3,
  },
  {
    index: 9,
    src: galleryImg10,
    width: 3,
    height: 4,
  },
  {
    index: 10,
    src: galleryImg11,
    width: 4,
    height: 3,
  },
  {
    index: 11,
    src: galleryImg12,
    width: 5,
    height: 3,
  },
  {
    index: 12,
    src: galleryImg13,
    width: 4,
    height: 3,
  },
  {
    index: 13,
    src: galleryImg14,
    width: 4,
    height: 3,
  },
  {
    index: 14,
    src: galleryImg15,
    width: 3,
    height: 5,
  },
  {
    index: 15,
    src: galleryImg16,
    width: 4,
    height: 3,
  },
  {
    index: 16,
    src: galleryImg17,
    width: 4,
    height: 3,
  },
  {
    index: 17,
    src: galleryImg18,
    width: 4,
    height: 3,
  },
  {
    index: 18,
    src: galleryImg19,
    width: 5,
    height: 4,
  },
  {
    index: 19,
    src: galleryImg20,
    width: 4,
    height: 3,
  },
  {
    index: 20,
    src: galleryImg21,
    width: 2,
    height: 3,
  },
  {
    index: 21,
    src: galleryImg22,
    width: 4,
    height: 3,
  },
  {
    index: 22,
    src: galleryImg23,
    width: 6,
    height: 3,
  },
  {
    index: 23,
    src: galleryImg24,
    width: 4,
    height: 3,
  },
  {
    index: 24,
    src: galleryImg25,
    width: 3,
    height: 3,
  },
  {
    index: 25,
    src: galleryImg26,
    width: 3,
    height: 4,
  },
  {
    index: 26,
    src: galleryImg27,
    width: 4,
    height: 3,
  },
  {
    index: 27,
    src: galleryImg28,
    width: 5,
    height: 3,
  },
  {
    index: 28,
    src: galleryImg29,
    width: 4,
    height: 3,
  },
  {
    index: 29,
    src: galleryImg30,
    width: 4,
    height: 3,
  },
  {
    index: 30,
    src: galleryImg31,
    width: 7,
    height: 5,
  },
  {
    index: 31,
    src: galleryImg32,
    width: 2,
    height: 3,
  },
  {
    index: 32,
    src: galleryImg33,
    width: 6,
    height: 5,
  },
];
