import faithImage from "../../../Images/AlumniPictures/faithBeevers.jpg";
import bethanyImage from "../../../Images/AlumniPictures/bethanyHoughton.jpg";
import wednesdayImage from "../../../Images/AlumniPictures/wednesdayHoughton.jpg";
import laurenImage from "../../../Images/AlumniPictures/laurenBowers.jpg";
import joeImage from "../../../Images/AlumniPictures/joeSanderson.jpg";
import annabelImage from "../../../Images/AlumniPictures/annabelGascoyne.jpg";
import shelbyImage from "../../../Images/AlumniPictures/shelbyWhite.jpg";
import christieImage from "../../../Images/AlumniPictures/christieBurgoyne.jpg";
import felicityImage from "../../../Images/AlumniPictures/felicityCowellClark.jpeg";
import emmaImage from "../../../Images/AlumniPictures/emmaHinds.jpeg";
import ellieshaImage from "../../../Images/AlumniPictures/ellieshaWiltshire2.jpg";
import richardImage from "../../../Images/AlumniPictures/richardCork.jpg";
import calumImage from "../../../Images/AlumniPictures/calumFlynn.jpg";
import annaImage from "../../../Images/AlumniPictures/annaKnowles.jpg";
import jackImage from "../../../Images/AlumniPictures/jackDargan.jpg";

const AlumniGridDetails = [
  {
    id: 1,
    firstName: "Bethany",
    surname: "Houghton",
    summary: "London Studio Centre",
    image: bethanyImage,
  },
  {
    id: 2,
    firstName: "Wednesday",
    surname: "Houghton",
    summary: "Performers College",
    image: wednesdayImage,
  },
  {
    id: 3,
    firstName: "Faith",
    surname: "Beevers",
    summary: "SLP College",
    image: faithImage,
  },
  {
    id: 4,
    firstName: "Lauren",
    surname: "Bowers",
    summary: "Northern Ballet School",
    image: laurenImage,
  },
  {
    id: 5,
    firstName: "Joe",
    surname: "Sanderson",
    summary: "CAPA Performing Arts College",
    image: joeImage,
  },
  {
    id: 6,
    firstName: "Elliesha",
    surname: "Wiltshire",
    summary: "Tring Park School for the Performing Arts",
    image: ellieshaImage,
  },
  {
    id: 7,
    firstName: "Christie",
    surname: "Burgoyne",
    summary: "BIRD Performing Arts College",
    image: christieImage,
  },
  {
    id: 8,
    firstName: "Felicity",
    surname: "Cowell-Clark",
    summary: "CAPA Performing Arts College",
    image: felicityImage,
  },
  {
    id: 9,
    firstName: "Emma",
    surname: "Hinds",
    summary: "Central School of Ballet & Italia Conti Academy",
    image: emmaImage,
  },
  {
    id: 10,
    firstName: "Annabel",
    surname: "Gasgoyne",
    summary: "Northern Ballet School",
    image: annabelImage,
  },
  {
    id: 11,
    firstName: "Shelby",
    surname: "White",
    summary: "BIRD Performing Arts College",
    image: shelbyImage,
  },
  {
    id: 12,
    firstName: "Richard",
    surname: "Cork",
    summary: "Tring Park School for the Performing Arts",
    image: richardImage,
  },
  {
    id: 13,
    firstName: "Calum",
    surname: "Flynn",
    summary: "Urdang Academy",
    image: calumImage,
  },
  {
    id: 14,
    firstName: "Anna",
    surname: "Knowles",
    summary: "London Studio Centre",
    image: annaImage,
  },
  {
    id: 15,
    firstName: "Jack",
    surname: "Dargan",
    summary: "Urdang Academy",
    image: jackImage,
  },
];

export default AlumniGridDetails;
