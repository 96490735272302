import { makeStyles } from "@material-ui/styles";
import staffHero from "../../../Images/StaffPictures/staffHero.jpg";

const StaffStyles = makeStyles((theme) => ({
  staffContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 0 0 ",
    },
    position: "relative",
    backgroundColor: "#000",
    color: "#fff",
    overflow: "hidden",
    height: "auto",
  },
  staffHeroContainer: {
    position: "relative",
    height: "750px",
  },
  staffBackgroundImage: {
    backgroundImage: `url(${staffHero})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  staffHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px",
    },
    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  staffHeroTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  staffGridContainer: {
    margin: "128px 0",
  },
  staffDetailsContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 32px 0",
    },
    padding: theme.spacing(8),
    justifyContent: "center",
  },
  staffTextContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
    padding: theme.spacing(8),
  },
  firstNameStyle: {
    [theme.breakpoints.down("sm")]: { fontSize: "2.2rem" },
    color: "#fff",
    fontSize: "3em",
    textAlign: "left",
    fontFamily: "Shadows Into Light, cursive",
  },
  surnameStyle: {
    [theme.breakpoints.down("sm")]: { fontSize: "2.2rem" },
    color: "#ee1d23",
    fontSize: "3rem",
    textAlign: "left",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "justify",
      padding: "5px 0",
    },
    padding: "20px 0px",
    color: "#fff",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "bold",
  },
  staffPicContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
    justifyContent: "center",
  },
  staffPic: {
    width: "100%",
    boxShadow: "7.5px 0px 0px #ee123d",
  },
  staffPicAlt: {
    width: "100%",
    boxShadow: "-7.5px 0px 0px #ee123d",
  },
}));

export default StaffStyles;
