import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../Images/CompetitionPictures/competitionHeroImage.jpg";

const CompetitionExamPageStyles = makeStyles((theme) => ({
  competitionContainer: {
    position: "relative",
    backgroundColor: "#000",
    color: "#fff",
    overflow: "hidden",
    height: "auto",
  },
  competitionHeroContainer: {
    position: "relative",
    height: "750px",
  },
  competitionHeroImage: {
    backgroundImage: `url(${backgroundImage})`,
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "brightness(50%)",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  competitionHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },

    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  competitionTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  competitionContent: {
    backgroundColor: "#000",
  },
  competitionText: {
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0",
    },
    fontFamily: "Poppins",
    padding: theme.spacing(4),
    alignSelf: "center",
  },
  competitionSummary: {
    fontSize: "1rem",
    color: "#fff",
  },
  competitionItem: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    width: "100%",
    padding: "64px 128px",
    backgroundColor: "#212020",
  },
  competitionItemAlt: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
    width: "100%",
    padding: "64px 128px",
  },
  competitionImageContainer: {
    [theme.breakpoints.up("md")]: {
      margin: "128px 0",
    },
    justifyContent: "center",
    overflow: "hidden",
  },
  competitionImage: {
    width: "100%",
    height: "100%",
    minWidth: "600px",
  },
}));

export default CompetitionExamPageStyles;
