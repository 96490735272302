import React from 'react';
import Home from '../../Pages/Home/Home.js';
import Overview from '../../Pages/Overview/Overview.js';
import ClassesMain from '../../Pages/ClassesMain/ClassesMain.js';
import JuniorsMain from '../../Pages/Juniors/JuniorsMain.js';
import Gallery from '../../Pages/Gallery/GalleryMainPage.js';
import StaffMain from '../../Pages/Staff/StaffMainPage.js';
import News from '../../Pages/News/NewsMainPage.js';
import Affiliates from '../../Pages/Affiliates/AffiliatesPage.js';
import CookieBanner from '../CookieBanner/CookieBanner.js';

const Main = () => {
  return (
    <div id="main">
      <CookieBanner />
      <Home id="home" />
      <Overview id="overview" />
      <ClassesMain id="classesMain" />
      <JuniorsMain id="juniorsMain" />
      <Gallery id="gallery" />
      <StaffMain id="staffMain" />
      <News id="news" />
      <Affiliates id="shows" />
    </div>
  );
};

export default Main;
