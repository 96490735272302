import { makeStyles } from "@material-ui/styles";
import studioImage from "../../Images/ShopPictures/shopMainImage.jpg";

const ShopPageStyles = makeStyles((theme) => ({
  shopPage: {
    position: "relative",
    backgroundColor: "#212020",
    color: "white",
    overflow: "hidden",
    height: "auto",
    padding: "64px 0",
  },
  shopTitle: {
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "3.2rem",
    textAlign: "center",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  divider: {
    width: "50%",
    border: 0,
    height: "1px",
    maxWidth: "450px",
    marginTop: 0,
    display: "block",
    borderTop: "2px solid #ee1d23",
    borderRadius: "50%",
    marginBottom: "5px",
  },
  divider2: {
    width: "30%",
    border: 0,
    height: "1px",
    maxWidth: "350px",
    marginTop: 0,
    display: "block",
    borderTop: "2px solid #ee1d23",
    borderRadius: "25%",
    marginBottom: 0,
  },
  shopInfo: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    padding: "64px 128px",
  },
  shopTextContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
    padding: theme.spacing(4),
  },
  shopText: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    color: "#fff",
    margin: "auto",
    fontSize: "1rem",
    maxWidth: "500px",
    textAlign: "left",
    fontFamily: "Poppins",
  },
  shopButtonContainer: {
    padding: "20px 0",
  },
  shopButtonLink: {
    textDecoration: "none",
  },
  shopButton: {
    border: "2px solid #ee1d23",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
      "& $arrow": {
        transform: "translate(50%)",
        color: "#fff",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
  },
  shopImage: {
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    width: "100%",
    height: "400px",
    justifyContent: "center",
    backgroundImage: `url(${studioImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignSelf: "center",
  },
}));

export default ShopPageStyles;
