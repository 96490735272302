import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import menuItems from "./NavbarMenuData.js";
import NavbarStyles from "./NavbarStyles.js";
import logo from "../../../Images/Icons/de-burnays-logo-main.png";

const Drawer = (props) => {
  const classes = NavbarStyles();
  const { history } = props;
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState({});

  const handleMenu = (event) => {
    setOpen(true);
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
  };

  const handleSubheadingClick = (id, value) => {
    setIsOpen({ ...isOpen, [id]: value });
  };

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        onClick={handleMenu}
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <MUIDrawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <List className={classes.nav}>
          {menuItems.map(({ title, pageURL, id, subHeadings }) => {
            return !subHeadings ? (
              <ListItem
                button
                onClick={() => handleMenuClick(pageURL)}
                className={classes.navButton}
              >
                <div className={classes.navLink} key={id}>
                  {title}
                </div>
              </ListItem>
            ) : (
              <>
                <ListItem
                  button
                  className={classes.navLink}
                  onClick={() => handleSubheadingClick(id, !isOpen[id])}
                >
                  {title}
                  {isOpen[id] ? (
                    <ExpandLess className={classes.expandArrow} />
                  ) : (
                    <ExpandMore className={classes.expandArrow} />
                  )}
                </ListItem>
                <Collapse in={isOpen[id]} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {subHeadings.map(({ pageURL, id, title }) => (
                      <ListItem
                        button
                        className={classes.subMenuStyle}
                        onClick={() => handleMenuClick(pageURL)}
                      >
                        <div to={pageURL} key={id}>
                          {title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            );
          })}
        </List>
      </MUIDrawer>
      <div className={classes.logoContainer}>
        <img
          src={logo}
          className={classes.logoStyle}
          alt="De Burnay's Logo"
          onClick={() => handleMenuClick("/")}
        />
      </div>
    </>
  );
};

export default withRouter(Drawer);
