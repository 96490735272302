import React, { useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import CompetitionExamPageStyles from "./CompetitionExamPageStyles.js";
import CompetitionExamGridDetails from "./CompetitionExamGridDetails.js";

const CompetitionExamPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = CompetitionExamPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.competitionPageContainer}>
      <div className={classes.competitionHeroContainer} alt="Home Page Cover">
        <div className={classes.competitionHeroImage} />
        <Grid container className={classes.competitionHeroText} lg={8} sm={12}>
          <h1 className={classes.competitionTitle}>
            Here to <span className={classes.secondaryText}>Compete</span>.
          </h1>
        </Grid>
      </div>

      <Grid container className={classes.competitionContent}>
        {CompetitionExamGridDetails.map((gridItem) => {
          const { id, image, p1, p2, p3 } = gridItem;
          return id % 2 !== 0 && !matches ? (
            <Grid container className={classes.competitionItem} xs={12}>
              <Grid
                id={id}
                container
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.competitionImageContainer}
              >
                <img src={image} className={classes.competitionImage} alt="" />
              </Grid>
              <Grid
                item
                container
                className={classes.competitionText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <p className={classes.competitionSummary}>{p1}</p>
                <p className={classes.competitionSummary}>{p2}</p>
                <p className={classes.competitionSummary}>{p3}</p>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.competitionItemAlt} xs={12}>
              <Grid
                item
                container
                className={classes.competitionText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <p className={classes.competitionSummary}>{p1}</p>
                <p className={classes.competitionSummary}>{p2}</p>
                <p className={classes.competitionSummary}>{p3}</p>
              </Grid>
              <Grid
                id={id}
                container
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.competitionImageContainer}
              >
                <img src={image} className={classes.competitionImage} alt="" />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
export default CompetitionExamPage;
