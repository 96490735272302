import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Toolbar, AppBar, useScrollTrigger, Slide } from "@material-ui/core";
import NavbarContent from "./NavbarContent/NavbarContent.js";
import NavbarStyles from "./NavbarContent/NavbarStyles.js";

const Navbar = () => {
  const classes = NavbarStyles();

  const HideOnScroll = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      trigger: window ? window() : undefined,
    });
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };

  return (
    <div className={classes.headerStyle} id="topAnchor">
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar className={classes.headerStyle}>
            <NavbarContent />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
};

export default withRouter(Navbar);
