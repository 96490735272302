import galleryImg from "../../Images/GalleryPictures/galleryImg1.jpg";
import galleryImg2 from "../../Images/GalleryPictures/galleryImg2.jpg";
import galleryImg3 from "../../Images/GalleryPictures/galleryImg3.jpg";
import galleryImg4 from "../../Images/GalleryPictures/galleryImg4.jpg";
import galleryImg5 from "../../Images/GalleryPictures/galleryImg5.jpg";
import galleryImg6 from "../../Images/GalleryPictures/galleryImg6.jpg";

const showGrid = [
  {
    id: 1,
    image: galleryImg,
  },
  {
    id: 2,
    image: galleryImg2,
  },
  {
    id: 3,
    image: galleryImg3,
  },
  {
    id: 4,
    image: galleryImg4,
  },
  {
    id: 5,
    image: galleryImg5,
  },
  {
    id: 6,
    image: galleryImg6,
  },
];

export default showGrid;
