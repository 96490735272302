import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../../Images/ClassPictures/classPageHero.jpg";

const ClassesStylesMain = makeStyles((theme) => ({
  classesContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 0 0 ",
    },
    position: "relative",
    backgroundColor: "#000",
    color: "#fff",
    overflow: "hidden",
    height: "auto",
  },
  classHeroContainer: {
    position: "relative",
    height: "750px",
  },
  classesHeroImage: {
    backgroundImage: `url(${backgroundImage})`,
    position: "absolute",
    backgroundAttachment: "fixed",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  classesText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px",
    },
    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  classesTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "justify",
      padding: "0 0 10% 0",
    },
    fontFamily: "Roboto, sans-serif",
    color: "#fff",
    fontSize: "16px",
    textAlign: "left",
    fontWeight: "bold",
  },
  classItem: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    width: "100%",
  },
  classPicContainer: {
    height: "100%",
  },
  classPic: {
    [theme.breakpoints.down("sm")]: {},
    width: "100%",
    height: "100%",
  },
  danceInfoContainer: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: "75px 75px 50px 75px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: "50px 8px",
    },
    padding: theme.spacing(16),
    alignSelf: "center",
    textAlign: "left",
  },
  danceTitleStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    fontSize: "3.2rem",
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  danceSummaryStyle: {
    fontSize: "1rem",
    fontFamily: "Poppins",
  },
  backToTopButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default ClassesStylesMain;
