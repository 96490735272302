import React, { useEffect } from "react";
import ContactPageStyles from "./ContactPageStyles.js";
import { Grid, Typography } from "@material-ui/core";
import ContactPageGridDetails from "./ContactPageGridDetails.js";
import Map from "../../Components/MapAPI/MapAPI.js";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = ContactPageStyles();
  return (
    <React.Fragment>
      <div className={classes.contactPage}>
        <div className={classes.contactHeroContainer}>
          <div className={classes.contactHeroImage} />
          <Grid container className={classes.contactHeroText} lg={8} sm={12}>
            <h1 className={classes.contactTitle}>
              Contact <span className={classes.reimaginedText}>us</span>.
            </h1>
          </Grid>
        </div>
        <Grid container className={classes.contactPageContent}>
          {ContactPageGridDetails.map((details) => {
            return (
              <Grid
                container
                item
                className={classes.contactDetails}
                md={4}
                sm={6}
                id={details.id}
              >
                <Typography>
                  <h2 className={classes.contactDetailsHeader}>
                    {details.title}
                  </h2>
                  <span className={classes.contactText}>{details.info1}</span>
                  <br />
                  <span className={classes.contactText}>{details.info2}</span>
                  <br />
                  <span className={classes.contactText}>{details.info3}</span>
                  <br />
                  <span className={classes.contactText}>{details.info4}</span>
                  <br />
                  <span className={classes.contactText}>{details.info5}</span>
                  <br />
                  <span className={classes.contactText}>{details.info6}</span>
                  <br />
                  <span className={classes.contactText}>{details.info7}</span>
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid className={classes.mapContainer}>
          <Map />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ContactPage;
