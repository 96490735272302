import { makeStyles } from '@material-ui/core';
import newsHero from '../../../Images/NewsPictures/newsHeroImage.jpg';

const NewsPageStyles = makeStyles((theme) => ({
  newsPage: {
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 0 0 ',
    },
    position: 'relative',
    backgroundColor: '#000',
    color: '#fff',
    overflow: 'hidden',
    height: 'auto',
  },
  newsContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 0 0 ',
    },
    position: 'relative',
    backgroundColor: '#000',
    color: '#fff',
    overflow: 'hidden',
    height: 'auto',
  },
  newsHeroContainer: {
    position: 'relative',
    height: '750px',
  },
  newsBackgroundImage: {
    backgroundImage: `url(${newsHero})`,
    backgroundAttachment: 'fixed',
    position: 'absolute',
    height: '100%',
    width: 'calc(100%)',
    top: '0',
    left: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: '0.4',
    '@supports (-webkit-touch-callout: none)': {
      backgroundAttachment: 'scroll',
    },
  },
  paper: {
    position: 'absolute',
    width: '75%',
    maxWidth: '400px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '8px',
    textAlign: 'center',
  },
  articleImageContainer: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  articleImage: {
    width: '50%',
    padding: '20px',
    minWidth: '200px',
    maxWidth: '300px',
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
  articleImageModal: {
    width: '100%',
  },
  newsHeroText: {
    [theme.breakpoints.down('sm')]: {
      padding: '128px 64px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '32px 12px',
    },
    position: 'relative',
    top: '0',
    padding: theme.spacing(16),
  },
  newsHeroTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
    fontFamily: 'Shadows Into Light, cursive',
    color: '#fff',
    fontSize: '5rem',
    textAlign: 'left',
  },
  secondaryText: {
    color: '#ee1d23',
    fontFamily: 'Shadows Into Light, cursive',
  },
  newsPageContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '64px 16px',
    },
    padding: '64px 128px',
  },
  newsPageTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.2rem',
    },
    fontSize: '5rem',
    fontFamily: 'Shadows Into Light, cursive',
    color: '#fff',
  },

  newsArticlesContainer: {
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '1.2rem',
  },
  article: {
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center',
    },
    margin: '20px 0',
    boxShadow: '0 0 15px #3f3f3f',
    borderRadius: '25px',
    padding: '30px',
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      margin: '1rem',
    },
    border: '0',
    borderTop: '1px solid #f44336',
    margin: '1em 0',
    width: '40%',
    maxWidth: '500px',
  },
  articleTitle: {
    fontFamily: 'Shadows Into Light, cursive',
    padding: 0,
  },
  articleDate: {
    fontSize: '0.8rem',
  },
  articleSummary: {
    textAlign: 'left',
    fontSize: '1rem',
  },
}));

export default NewsPageStyles;
