import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../Images/AboutPictures/aboutHeroImage.jpg";

const AboutStyles = makeStyles((theme) => ({
  aboutContainer: {
    position: "relative",
    backgroundColor: "#000",
    color: "#fff",
    overflow: "hidden",
    height: "auto",
  },
  aboutHeroContainer: {
    position: "relative",
    height: "750px",
  },
  aboutHeroImage: {
    backgroundImage: `url(${backgroundImage})`,
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  aboutHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },

    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },
  aboutTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  courtHouseText: {
    fontSize: "0.8rem",
  },
  aboutText: {
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0",
    },
    fontFamily: "Poppins",
    padding: theme.spacing(4),

    alignSelf: "center",
  },
  aboutItem: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    width: "100%",
    padding: "64px 128px",
    backgroundColor: "#212020",
  },
  aboutItemAlt: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
    width: "100%",
    padding: "64px 128px",
  },
  aboutImageContainer: {
    [theme.breakpoints.up("md")]: {
      margin: "128px 0",
    },
    justifyContent: "center",
    overflow: "hidden",
  },
  aboutImage: {
    width: "100%",
    height: "100%",
    minWidth: "600px",
  },
}));

export default AboutStyles;
