const FooterLinks = [
  {
    id: 1,
    linkName: "About",
    url: "/about",
  },
  {
    id: 2,
    linkName: "Classes",
    url: "/classes",
  },
  {
    id: 3,
    linkName: "Juniors",
    url: "/juniors",
  },
  {
    id: 4,
    linkName: "Competition and Exams",
    url: "/competition",
  },
  {
    id: 5,
    linkName: "Our Team",
    url: "/team",
  },
  {
    id: 6,
    linkName: "Shop",
    url: "/shop",
  },
  {
    id: 7,
    linkName: "Gallery",
    url: "/gallery",
  },
  {
    id: 8,
    linkName: "News",
    url: "/news",
  },
  {
    id: 9,
    linkName: "Contact",
    url: "/contact",
  },
];

export default FooterLinks;
