import { makeStyles } from "@material-ui/core/styles";

const navbarStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: "#131312",
    padding: "10px 16px",
  },
  menuButton: {
    transform: "scale(1.8)",
  },
  nav: {
    height: "100%",
    width: "200px",
    color: "#fff",
    backgroundColor: "#131312",
    paddingTop: "20px",
  },
  navButton: {
    "&:hover": {
      "& $navLink": {
        color: "#ee1d23",
      },
    },
  },
  navLink: {
    fontSize: "1.5rem",
    textDecoration: "none",
    color: "#fff",
    fontFamily: "Poppins",
    transition: "0.15s ease",
  },
  subMenuStyle: {
    fontSize: "1.2rem",
    paddingLeft: theme.spacing(4),
    textDecoration: "none",
    color: "#fff",
    fontFamily: "Poppins",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  expandArrow: {
    color: "#ee1d23",
  },
  logoContainer: {
    [theme.breakpoints.down("sm")]: { justifyContent: "center" },
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  },
  logoStyle: {
    [theme.breakpoints.up("sm")]: { alignSelf: "center" },
    display: "flex",
    width: "160px",
    height: "50px",
    cursor: "pointer",
  },
}));

export default navbarStyles;
