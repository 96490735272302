import React, { useEffect } from "react";
import ShopPageStyles from "./ShopPageStyles.js";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import ShopGridDetails from "./ShopPageDetails.js";

const ShopPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = ShopPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.shopPage}>
      <div className={classes.shopHeroContainer} alt="Home Page Cover">
        <div className={classes.shopBackgroundImage} />
        <Grid container className={classes.shopHeroText} lg={8} sm={12}>
          <h1 className={classes.shopHeroTitle}>
            Our <span className={classes.secondaryText}>Shop</span>.
          </h1>
        </Grid>
      </div>

      <Grid className={classes.shopContent}>
        {ShopGridDetails.map((gridItem) => {
          const { id, image, p1, p2, p3 } = gridItem;
          return id % 2 !== 0 && !matches ? (
            <Grid container className={classes.shopItem} xs={12}>
              <Grid
                id={id}
                container
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.shopImageContainer}
              >
                <img src={image} className={classes.shopImage} alt="" />
              </Grid>
              <Grid
                item
                container
                className={classes.shopText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <p className={classes.shopSummary}>{p1}</p>
                <p className={classes.shopSummary}>{p2}</p>
                <p className={classes.shopSummary}>{p3}</p>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.shopItemAlt} xs={12}>
              <Grid
                item
                container
                className={classes.shopText}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <p className={classes.shopSummary}>{p1}</p>
                <p className={classes.shopSummary}>{p2}</p>
                <p className={classes.shopSummary}>{p3}</p>
              </Grid>
              <Grid
                id={id}
                container
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.shopImageContainer}
              >
                <img src={image} className={classes.shopImage} alt="" />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ShopPage;
