import React from "react";
import CookieBanner from "react-cookie-banner";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const CookieBannerComponent = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return !matches ? (
    <div>
      <CookieBanner
        styles={{
          banner: {
            position: "absolute",
            backgroundColor: "#ee1d23",
            top: "650px",
            fontFamily: "Poppins",
            height: "auto",
            opacity: "0.8",
          },
          message: { fontWeight: "bold" },
          button: {
            fontFamily: "Shadows Into Light, cursive",
            fontSize: "1.5rem",
            left: "50%",
            background: "none",
            top: "60%",
            position: "unset",
            color: "#000",
          },
        }}
        message="We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it."
        onAccept={() => {}}
        cookie="user-has-accepted-cookies"
      />
    </div>
  ) : (
    <div>
      <CookieBanner
        styles={{
          banner: {
            position: "absolute",
            backgroundColor: "#ee1d23",
            top: "550px",
            fontFamily: "Poppins",
            height: "auto",
            opacity: "0.8",
          },
          message: { fontWeight: "bold" },
          button: {
            fontFamily: "Shadows Into Light, cursive",
            fontSize: "1.5rem",
            left: "50%",
            background: "none",
            top: "60%",
            position: "unset",
            color: "#000",
          },
        }}
        message="We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it."
        onAccept={() => {}}
        cookie="user-has-accepted-cookies"
      />
    </div>
  );
};

export default CookieBannerComponent;
