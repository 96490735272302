import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../../Images/GalleryPictures/GalleryPagePictures/galleryHeroImage.jpg";

const GalleryPageStyles = makeStyles((theme) => ({
  galleryPage: {
    background: "#000",
    height: "auto",
    overflow: "hidden",
  },
  galleryHeroContainer: {
    position: "relative",
    height: "750px",
  },
  galleryHeroImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  galleryPageTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.2rem",
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
    padding: "128px",
    position: "relative",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  galleryContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    margin: "100px 0px",
  },
  gallerySummary: {
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1rem",
    marginBottom: "100px",
    maxWidth: "550px",
    margin: "auto",
    textAlign: "center",
  },
  zTenLink: {
    textDecoration: "none",
    color: "#ee1d23",
    transition: "0.2s",
    "&:hover": {
      color: "#ddd",
    },
  },
}));

export default GalleryPageStyles;
