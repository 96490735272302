import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const FooterSocialLinks = [
  {
    id: 1,
    url: "https://www.facebook.com/DeBurnays-Dance-Centre-629911807083300/",
    Icon: FacebookIcon,
  },
  {
    id: 1,
    url: "https://www.instagram.com/deburnays_dance_centre/?hl=en",
    Icon: InstagramIcon,
  },
];

export default FooterSocialLinks;
