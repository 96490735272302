import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../../Images/AlumniPictures/alumniHero.jpg";

const AlumniPageStyles = makeStyles((theme) => ({
  alumniPage: {
    overflow: "hidden",
    height: "auto",
    backgroundColor: "#000",
  },
  alumniHeroContainer: {
    position: "relative",
    height: "750px",
    padding: "64px 0",
  },
  alumniHeroImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  alumniHeroText: {
    [theme.breakpoints.down("sm")]: {
      padding: "128px 64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px",
    },
    position: "relative",
    top: "0",
    padding: theme.spacing(16),
  },

  alumniContext: {
    [theme.breakpoints.down("sm")]: {
      padding: "64px 0",
    },
    padding: "64px",
  },
  alumniTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "left",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  alumniGrid: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    gridGap: "25px",
    marginBottom: "50px",
    justifyContent: "center",
  },
  alumniDetailsContainer: {
    justifySelf: "center",
    paddingBottom: "16px",
  },
  alumniPicContainer: {
    height: "400px",
    textAlign: "center",
  },
  alumniPic: {
    height: "100%",
    boxShadow: "0px 4px 0px #ee1d23",
    
  },
  alumniTextContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
    padding: "16px 0",
    position: "relative",
    justifyContent: "center",
  },
  alumniText: {
    textAlign: "center",
  },
  firstNameStyle: {
    [theme.breakpoints.down("sm")]: { fontSize: "2.5rem" },
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: "Shadows Into Light, cursive",
  },
  surnameStyle: {
    [theme.breakpoints.down("sm")]: { fontSize: "2.5rem" },
    color: "#ee1d23",
    fontSize: "2rem",
    textAlign: "left",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",

      padding: "5px 0",
    },
    color: "#fff",
    fontSize: "1rem",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
}));

export default AlumniPageStyles;
