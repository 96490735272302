import React, { useEffect } from "react";
import ClassesPageStyles from "./ClassesPageStyles.js";
import ClassesGrid from "./ClassesGridData.js";
import { useTheme } from "@material-ui/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import "../../../Components/aosAnimations/aosAnimations.css";

const ClassesMain = () => {
  const classes = ClassesPageStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.classesContainer}>
        <div className={classes.classHeroContainer}>
          <div className={classes.classesHeroImage} />
          <Grid item container className={classes.classesText} lg={8} sm={12}>
            <h1 className={classes.classesTitle}>
              Our <span className={classes.secondaryText}>Classes</span>.
            </h1>
          </Grid>
        </div>

        <Grid container>
          {ClassesGrid.map((gridItem) => {
            const { id, image, danceType, danceSummary } = gridItem;
            return id % 2 !== 0 && !matches ? (
              <Grid container className={classes.classItem} xs={12}>
                <Grid sm={12} md={6} lg={6} xl={6}>
                  <img
                    key={id}
                    src={image}
                    className={classes.classPic}
                    alt="Dance Type"
                  />
                </Grid>
                <Grid
                  container
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.danceInfoContainer}
                >
                  <div className={classes.danceTitleStyle}>{danceType}</div>
                  <div className={classes.danceSummaryStyle}>
                    {danceSummary}
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.classItem} xs={12}>
                <Grid
                  container
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.danceInfoContainer}
                >
                  <div className={classes.danceTitleStyle}>{danceType}</div>
                  <div className={classes.danceSummaryStyle}>
                    {danceSummary}
                  </div>
                </Grid>
                <Grid
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.classPicContainer}
                >
                  <img
                    src={image}
                    className={classes.classPic}
                    alt="Dance Type"
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default ClassesMain;
