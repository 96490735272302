import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MapAPIStyles from "./MapAPIStyles.js";

const MapAPI = () => {
  const classes = MapAPIStyles();
  const position = [53.3217, -0.94315];
  return (
    <MapContainer
      center={position}
      zoom={16}
      scrollWheelZoom={false}
      className={classes.map}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>De Burnay's Dance Centre</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapAPI;
