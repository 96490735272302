import { makeStyles } from "@material-ui/styles";
import backgroundImage from "../../Images/HomePictures/HomePageImg.jpg";

const HomeStyles = makeStyles((theme) => ({
  homeContainer: {
    position: "relative",
    minHeight: "750px",
    width: "100%",
    backgroundColor: "black",
  },
  backgroundImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundAttachment: "fixed",
    position: "absolute",
    height: "100%",
    width: "calc(100%)",
    top: "0",
    left: "0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    opacity: "0.4",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
  },
  homePageTextContainer: {
    [theme.breakpoints.down("xs")]: {
      left: "3%",
      right: "3%",
    },
    position: "absolute",
    top: "25%",
    left: "5%",
    textAlign: "left",
  },
  homePageTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    color: "#fff",
    fontSize: "4rem",
    fontFamily: "Shadows Into Light, cursive",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  homePageSummary: {
    color: "#fff",
    fontSize: "1.2rem",
    fontFamily: "Poppins, sans-serif",
    maxWidth: "500px",
    margin: "auto",
  },
}));

export default HomeStyles;
