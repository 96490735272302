import { makeStyles } from "@material-ui/styles";

const AffiliatesStyles = makeStyles((theme) => ({
  affiliatesContainer: {
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(1) },
    position: "relative",
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    height: "auto",
    backgroundColor: "#131212",
  },
  affiliatesText: {
    justifyContent: "center",
    textAlign: "-webkit-center",
  },
  affiliatesTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    textAlign: "center",
    fontFamily: "Shadows Into Light, cursive",
    fontSize: "3.2rem",
    color: "#fff",
  },
  secondaryText: {
    color: "#ee1d23",
  },
  affiliatesSummary: {
    color: "#fff",
    maxWidth: "500px",
    textAlign: "center",
  },
  affiliatesGrid: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    justifyContent: "center",
    position: "relative",
    width: "100%",
    padding: "64px",
  },
  affiliatesList: {
    padding: theme.spacing(2),
    justifyContent: "center",
    height: "100%",
  },
  affiliatesLogo: {
    width: "100%",
    maxWidth: "150px",
    height: "100px",
  },
}));

export default AffiliatesStyles;
