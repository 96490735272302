import juniorImage from "../../../Images/JuniorPictures/juniorImage1.jpg";
import juniorImage2 from "../../../Images/JuniorPictures/juniorImage2.jpg";

const JuniorGridDetails = [
  {
    id: 1,
    title: {
      main: "Building",
      secondary: " Blocks",
    },
    image: juniorImage,
    summary: {
      p1:
        "Our pre school classes are a wonderful way to introduce your child to the world of dance. Our Twinkle Toes and Pre Primary groups are small classes for the under 5’s, and for under 2’s we have our Teenie Toes and Happy Feet parent and toddler classes.",
      p2:
        "The classes are magical, creative and fun. These Pre school classes for under 5’s are lively structured dance classes for children that develop physical skills and encourage creativity, expression and confidence.",
      p3:
        "Children will learn to move with control and coordination and gain musical awareness and expression through story themed classes, incorporating different kinds of props such as musical instruments, ribbons, and costumes.",
      p4:
        "Children have the opportunity to get inspired and creative in a playful and enjoyable environment but at the same time learning the basic steps. This will be achieved through the different story lines used in each session where necessary ballet steps are introduced and mastered each time.",
      p5:
        "As a result, children will explore the capabilities of their own bodies, develop their creativity and imagination, enhance their confidence and social skills, and learn how to express themselves through movement and music. We believe that this class is a great example of showing how learning can take place in a fun, creative and safe environment, and at the same time how a strong relationship can be shaped between the teacher and the child.",
    },
  },
  {
    id: 2,
    title: {
      main: "Fun all ",
      secondary: "around",
    },
    image: juniorImage2,
    summary: {
      p1:
        "I am sure we have all witnessed preschoolers grooving to music and you can’t help but smile…",
      p2:
        "Children learn new things, like sounds, words and patterns through music. And with dance, they can explore and control their body movements.  These activities are great for development and stimulate connections in the growing brain.",
      p3:
        "We all know children are spending more time in front of screens rather than physically playing. Music and dance encourages them to be curious and imaginative.",
      p4:
        "Engaging in creative activities lets kids release their emotions and express their feelings.  It’s a fantastic opportunity for children who aren’t yet able put their thoughts into words!",
    },
  },
];

export default JuniorGridDetails;

/* Our pre school classes are a wonderful way to introduce you child to the world of dance. Our Twinkle Toes and Pre Primary are small group dance classes for the under 5’s and Teenie Toes and Happy Feet parent and toddler classes for under 2’s.

The classes are magical, creative and fun. These Pre school classes for under 5’s are lively structured dance classes for children that develop physical skills and encourage creativity, expression and confidence. 


Children will learn to move with control and coordination and gain musical awareness and expression through story themed classes, incorporating different kinds of props such as musical instruments, ribbons, costumes and sensitivity to music

Children have the opportunity to get inspired and creative in a playful and enjoyable environment but at the same time learning the basic steps. This will be achieved through the different story lines used in each session where necessary ballet steps are introduced and mastered each time.

As a result, children will explore the capabilities of their own bodies, develop their creativity and imagination, enhance their confidence and social skills, and learn how to express themselves through movement and music. 

We believe that this class is a great example of showing how learning can take place in a fun, creative and safe environment, and at the same time how a strong relationship can be shaped between the teacher and the child. */
