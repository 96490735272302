import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import GalleryPageStyles from "./GalleryPageStyles.js";
import Gallery from "../../../Components/GalleryPageComponent/GalleryComponent.js";

const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = GalleryPageStyles();
  return (
    <div className={classes.galleryPage}>
      <div className={classes.galleryHeroContainer}>
        <div className={classes.galleryHeroImage} />
        <Grid className={classes.galleryText}>
          <h1 className={classes.galleryPageTitle}>
            Our <span className={classes.secondaryText}>Gallery</span>.
          </h1>
        </Grid>
      </div>
      <div className={classes.galleryContainer}>
        <Gallery />
      </div>
    </div>
  );
};

export default GalleryPage;
