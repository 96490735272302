import React from "react";
import { useScrollTrigger, Fab, Zoom } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScrollArrowStyles from "./ScrollTopArrowStyles";

const ScrollTop = (props) => {
  const classes = ScrollArrowStyles();
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 300,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#topAnchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} className={classes.backToTopButton}>
        {children}
      </div>
    </Zoom>
  );
};

const ScrollTopArrow = (props) => {
  return (
    <ScrollTop {...props}>
      <Fab color="secondary" size="small">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
};

export default ScrollTopArrow;
