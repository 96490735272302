import { makeStyles } from "@material-ui/styles";
import alumniPic from "../../Images/AlumniPictures/alumniMainPic.jpg";

const AlumniMainPageStyles = makeStyles((theme) => ({
  alumniMainPage: {
    padding: "128px 0",
    position: "relative",
    backgroundColor: "#000",
    color: "white",
    overflow: "hidden",
    height: "auto",
  },
  alumniText: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
    padding: "64px",
  },
  alumniTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
      textAlign: "justify",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "2.5rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
    padding: "15px 0",
  },
  alumniPic: {
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    width: "100%",
    height: "100%",
    justifyContent: "center",
    backgroundImage: `url(${alumniPic})`,
    backgroundSize: "cover",
    alignSelf: "center",
  },
  alumniButtonContainer: {
    padding: "20px 0",
  },
  alumniButtonLink: {
    textDecoration: "none",
  },
  alumniButton: {
    border: "2px solid #ee1d23",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
      "& $arrow": {
        transform: "translate(50%)",
        color: "#fff",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
  },
}));

export default AlumniMainPageStyles;
