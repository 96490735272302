import { makeStyles } from "@material-ui/core/styles";
import staffMainPic from "../../Images/StaffPictures/staffMainImage.jpg";

const StaffMainPageStyles = makeStyles((theme) => ({
  staffMainContainer: {
    padding: "128px 0",
    position: "relative",
    backgroundColor: "#000",
    color: "white",
    overflow: "hidden",
    height: "auto",
    
  },
  staffMainText: {
    [theme.breakpoints.down("sm")]: {
      padding: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
    padding: "64px",
  },
  staffMainTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "2.5rem",
    textAlign: "left",
    width: "500px",
    margin: "auto",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    maxWidth: "500px",
    margin: "auto",
    padding: "15px 0",
  },
  staffMainPic: {
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    width: "100%",
    height: "100%",
    justifyContent: "center",
    backgroundImage: `url(${staffMainPic})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignSelf: "center",
  },
  staffMainButtonContainer: {
    padding: "20px 0",
  },
  staffMainButtonLink: {
    textDecoration: "none",
  },
  staffMainButton: {
    border: "2px solid #ee1d23",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      color: "#ee1d23",
      "& $arrow": {
        transform: "translate(50%)",
        color: "#fff",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
  },
}));

export default StaffMainPageStyles;
