import React from "react";
import { Link } from "react-router-dom";
import NewsMainPageStyles from "./NewsPageMainStyles.js";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const NewsMainPage = () => {
  const classes = NewsMainPageStyles();

  return (
    <div className={classes.staffMainContainer}>
      <Grid container className={classes.staffMainInfoContainer}>
        <Grid container item sm={12} md={6} lg={6}>
          <div className={classes.staffMainPic} />
        </Grid>
        <Grid
          item
          container
          className={classes.staffMainText}
          sm={12}
          md={6}
          lg={6}
        >
          <Grid container xs={12}>
            <h2 data-aos="fade-left" className={classes.staffMainTitle}>
              What's
              <span className={classes.secondaryText}> New</span>?
            </h2>
          </Grid>
          <Grid container xs={12}>
            <br />
            <div className={classes.summaryStyle}>
              We keep the latest De Burnays Dance Centre news here so you
              always know what is going on. Keep an eye out for all our updates
              here.
              <Grid container className={classes.staffMainButtonContainer}>
                <Link to="/news" className={classes.staffMainButtonLink}>
                  <Button className={classes.staffMainButton}>
                    News <ArrowForwardIcon className={classes.arrow} />
                  </Button>
                </Link>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewsMainPage;
