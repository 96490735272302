import { makeStyles } from "@material-ui/core/styles";

const OverviewStyles = makeStyles((theme) => ({
  overviewContainer: {
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(8) },
    [theme.breakpoints.down("xs")]: { padding: "64px 16px" },
    height: "auto",
    background: "#000",
    color: "#fff",
    padding: "100px 0",
  },
  gridContent: {
    justifyContent: "center",
  },
  overviewTextContainer: {
    maxWidth: "500px",
    margin: "auto",
    marginBottom: "80px",
  },
  overviewTitleStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    fontFamily: "Shadows Into Light, cursive",
    color: "#fff",
    fontSize: "3.2rem",
    textAlign: "center",
  },
  secondaryText: {
    color: "#ee1d23",
    fontFamily: "Shadows Into Light, cursive",
  },
  summaryStyle: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 40px 0",
    },
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "1.1rem",
    textAlign: "center",
  },
  overviewCardContainer: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      padding: "0",
    },
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "25px",
    justifyContent: "space-evenly",
    marginBottom: "50px",
    padding: "0 100px",
  },
  overviewCard: {
    justifyContent: "center",
    textAlign: "-webkit-center",
    padding: "32px 16px",
    boxShadow: "0 0 15px #3f3f3f",
    borderRadius: "25px",
    maxWidth: "500px",
    justifySelf: "center",
  },
  overviewCardIcon: {
    filter: "invert()",
    width: "50px",
    height: "50px",
    justifySelf: "center",
  },
  divider: {
    display: "block",
    height: "1px",
    border: "0",
    borderTop: "2px solid #ee1d23",
    margin: "1em 0",
    marginBottom: "25px",
    width: "50%",
  },
  overviewCardText: {
    justifyContent: "center",
  },
  overviewCardTitle: {
    padding: "16px 0px",
  },
  overviewCardSummary: {
    fontSize: "0.9rem",
    fontFamily: "Poppins",
  },
  overviewButtonContainer: {
    padding: "20px 0",
    justifyContent: "center",
  },
  overviewButtonLink: {
    textDecoration: "none",
  },
  overviewButton: {
    color: "#fff",
    textTransform: "none",
    fontSize: "1rem",
    alignSelf: "center",
    transition: "0.2s ease",
    "&:hover": {
      "& $arrow": {
        transform: "translate(50%)",
      },
    },
  },
  arrow: {
    width: "15px",
    margin: "5px",
    transition: "0.2s ease",
    color: "#ee1d23",
  },
  childProtectionContainer: {
    textAlign: "-webkit-center",
  },
  childProtectionText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    color: "#fff",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "1.5rem",
    fontFamily: "Poppins",
    transition: "0.2s ease",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      color: "#ee1d23",
    },
  },
  downArrow: {
    [theme.breakpoints.down("sm")]: {
      height: "15px",
    },
    position: "absolute",
    top: "5px",
    margin: "0 10px",
    color: "#ee1d23",
  },
}));

export default OverviewStyles;
