import image from "../../Images/CompetitionPictures/competitionImage.jpg";
import image2 from "../../Images/CompetitionPictures/competitionImage2.jpg";

const CompetitionExamGridDetails = [
  {
    id: 1,
    image: image,
    p1:
      "Festivals are a fantastic way for students to develop their confidence, refine their skills and learn to express themselves to a live audience.",
    p2:
      "Students can showcase their talents in a competitive environment and push themselves harder to strive for a more polished performance and better technique. Students can perform solos, duets, trios, quartets, and also group routines which will be judged by an adjudicator.",
    p3:
      "A high-level of performance skills are required, but there can be a great sense of pride and achievement. Competitions are always fun, and whether win or lose there is always something to learn.",
  },
  {
    id: 2,
    image: image2,
    p1:
      "Examinations and assessments are an important part of learning to dance, and as such are offered to all students. Whilst all of our examinations and assessments are optional, they provide an important and achievable goal for students to work towards, and are an effective method of monitoring personal progress and development.",
    p2:
      "We at De Burnays offer the chance to improve your confidence, overall ability and potential for attending prestigious vocational dance and performing arts colleges.",
    p3:
      "We have multiple teachers in our school who are fully qualified to teach various dance disciplines, and regularly put our students forward for examinations with exceptional pass rates.",
  },
];

export default CompetitionExamGridDetails;
