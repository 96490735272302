import React from "react";
import AffiliatesStyles from "./AffiliatesStyles.js";
import { Grid } from "@material-ui/core";
import affiliateGridData from "./AffiliatesGridData.js";

export default function AffiliatesPage() {
  const classes = AffiliatesStyles();
  return (
    <div className={classes.affiliatesContainer}>
      <Grid item md={12} className={classes.affiliatesText}>
        <h2 className={classes.affiliatesTitle}>
          Our
          <span className={classes.secondaryText}> Affiliates</span>.
        </h2>
        <div className={classes.affiliatesSummary}>
          We are affiliated with the most prestigious, renowed dance schools and
          partners across the UK. As such, our curriculum is always relevant,
          ensuring we teach only to the highest standard in all disciplines.
        </div>
      </Grid>
      <Grid container className={classes.affiliatesGrid}>
        {affiliateGridData.map((affiliateItem, index) => {
          const { image, url } = affiliateItem;
          return (
            <Grid
              container
              className={classes.affiliatesList}
              xs={12}
              sm={12}
              md={3}
              lg={3}
            >
              <a
                href={url}
                alt="Affiliates"
                add
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={index}
                  src={image}
                  className={classes.affiliatesLogo}
                  alt="Affiliate Logo"
                />
              </a>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
